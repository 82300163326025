export enum CreditScoreStatusType {
  EXCELLENT = 'EXCELLENT',
  GREAT = 'GREAT',
  AVERAGE = 'AVERAGE',
  UNKNOWN = 'UNKNOWN',
  RISK = 'RISK',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
  SIX = 'SIX',
  SEVEN = 'SEVEN',
  EIGHT = 'EIGHT',
  NINE = 'NINE',
  TEN = 'TEN'
}

export const SCORE_ICON_MAP = {
  [CreditScoreStatusType.EXCELLENT]: {
    color: '#23a800',
    icon: 'assignment_turned_in',
  },
  [CreditScoreStatusType.GREAT]: {
    color: '#a4dc5f',
    icon: 'thumbs_up_down',
  },
  [CreditScoreStatusType.AVERAGE]: {
    color: '#999999',
    icon: 'assignment_late',
  },
  [CreditScoreStatusType.UNKNOWN]: {
    color: '#ea7500',
    icon: 'assignment',
  },
  [CreditScoreStatusType.RISK]: {
    color: '#c9211e',
    icon: 'assignment',
  },
  [CreditScoreStatusType.ONE]: {
    color: '#23a800',
  },
  [CreditScoreStatusType.TWO]: {
    color: '#359903',
  },
  [CreditScoreStatusType.THREE]: {
    color: '#488A07',
  },
  [CreditScoreStatusType.FOUR]: {
    color: '#5A7B0A',
  },
  [CreditScoreStatusType.FIVE]: {
    color: '#6D6C0D',
  },
  [CreditScoreStatusType.SIX]: {
    color: '#7F5D11',
  },
  [CreditScoreStatusType.SEVEN]: {
    color: '#924E14',
  },
  [CreditScoreStatusType.EIGHT]: {
    color: '#A43F17',
  },
  [CreditScoreStatusType.NINE]: {
    color: '#B7301B',
  },

  [CreditScoreStatusType.TEN]: {
    color: '#c9211e',
  },
};


export class CreditScore {
  id: number;
  model_id: number;
  credit_id: number;
  user_id: number;
  request_data: string;
  response_data: string;
  score: number;
  created_at: string;
  operator_id: number;
  operator_email: string;
  model_name: string;
}

export enum CreditScoreModelNames {
  CENTRYCS = 'Centrycs_20200915_NEW_INSTALLMENTS',
  INTERNAL = 'INTERNAL',
  INTERNAL_PAYDAY = 'INTERNAL_PAYDAY',
  INSTALLMENTS_LOAN_NEW_CLIENT = 'INSTALLMENTS_LOAN_NEW_CLIENT_2023',
  PAYDAY_NEW_CLIENT_2023 = 'PAYDAY_NEW_CLIENT_2023',
  INTERNAL_SCORING_REPEAT_CLIENT_2024 = 'INTERNAL_SCORING_REPEAT_CLIENT_2024',
  INSTALLMENTS_LOAN_REPEAT_CLIENT_2024 = 'INSTALLMENTS_LOAN_REPEAT_CLIENT_2024'
}