@if (combinedStatus?.name) {
@if (compactView) {
<!-- <div [matTooltip]="('verification.verificationStatusOptionsCombinedTooltip.' + combinedStatus.name.toLowerCase()) | translate"
     matTooltipPosition="right"
     class="icon-button-container">
  <button mat-icon-button
          [matTooltip]="combinedStatus.reasons | verificationStatusReasonToTranslationKey"
          matTooltipPosition="left"
          (click)="editVerificationStatus()">
    <mat-icon [ngStyle]="{
  color: styleMap[combinedStatus?.name]?.backgroundColor
  }">{{styleMap[combinedStatus?.name]?.icon}}</mat-icon>
  </button>
</div> -->

<div>
  <span layout="row"
        layout-align="start center">
    <button mat-icon-button
            (click)="editVerificationStatus()"
            [matTooltip]="('verification.verificationStatusOptionsCombinedTooltip.' + combinedStatus.name.toLowerCase()) | translate"
            matTooltipPosition="above">
      <mat-icon [ngStyle]="{
        color: styleMap[combinedStatus?.name]?.backgroundColor
        }">{{styleMap[combinedStatus?.name]?.icon}}</mat-icon>
    </button>
    @if (displayLabel) {
    <span>{{('verification.verificationStatusOptionsCombinedShort.' + combinedStatus.name.toLowerCase()) |
      translate}}</span>
    }
  </span>
</div>

} @else {
<div [matTooltip]="('verification.verificationStatusOptionsCombinedTooltip.' + combinedStatus.name.toLowerCase()) | translate"
     matTooltipPosition="below">
  <button class="verification-status"
          (click)="editVerificationStatus()"
          mat-stroked-button
          [matTooltip]="combinedStatus.reasons | verificationStatusReasonToTranslationKey"
          matTooltipPosition="above">
    <div layout="row"
         layout-align="space-between center">
      <mat-icon matChipAvatar
                [ngStyle]="{
                color: styleMap[combinedStatus?.name]?.backgroundColor
              }">{{styleMap[combinedStatus?.name]?.icon}}</mat-icon>
      {{ "verification.verificationStatusCombined." + combinedStatus.name.toLowerCase() | translate }}
    </div>
  </button>
</div>
}
}
