import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EventLogStateService } from '../right-drawer/event-log/event-log-state.service';
import { CallService } from '@app/core/services/call.service';
import {
  EventLogHistoryType,
  EventLogMode,
  EventLogSubheader,
} from '../right-drawer/shared/event-log.enums';
import { CallCategoryTypes, Category, ChCallCreatedDto, ChCallsDto } from './types';
import { Subject, takeUntil } from 'rxjs';
import { Call, HIDDEN_PHONE_NUMBER } from '@app/core/types/call';
import { Country } from '@app/core/types/country';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CountryService } from '@app/core/services/country.service';
import { Socket } from '@app/main/socket-io/socket-io.service';
import { PbxEvents } from '@app/main/socket-io/types/pbx-event.enum';
import { SessionService } from '@app/core/services/session.service';
import parsePhoneNumberFromString, {
  AsYouType,
  CountryCode,
  PhoneNumber,
} from 'libphonenumber-js';
import { UserDetails } from '@app/core/types/user-details';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { CallStatusTranslationPipe } from '@app/shared/pipes/call-status-translation.pipe';
import { CallCategories, NO_AGENT_CATEGORY_CONFIG } from '@app/config/call-config';
import { EventLogExtendedState } from '../right-drawer/shared/state.interfaces';
import { NotificationService } from '@app/core/services/notification.service';
import { EventLogService } from '@app/core/services/event-log.service';

const customTooltipOptions: Partial<MatTooltipDefaultOptions> = {
  disableTooltipInteractivity: true,
};

@Component({
  selector: 'itfg-call-hub',
  templateUrl: './call-hub.component.html',
  styleUrls: ['./call-hub.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipOptions },
    CallStatusTranslationPipe
  ],
})
export class CallHubComponent implements OnInit, AfterViewInit, OnDestroy {
  categories: Category[] = CallCategories;
  selectedCategory: Category;
  calls: Call[] = [];
  selectedCall: Call;
  countries: Country[] = [];
  matchingCountries: Country[] = [];
  selectedCountry: Country;
  operatorId: number;
  hiddenNumber: string = HIDDEN_PHONE_NUMBER;
  click2CallForm: UntypedFormGroup;
  currentlyLoggedInOperator: UserDetails;
  callsNoAgentCategory: Category = NO_AGENT_CATEGORY_CONFIG;
  isEnterPressed: boolean = false;
  btnJiggleAnimation: boolean = false;
  isFetchingCalls: boolean = false;
  connectingToPbx$: Subject<boolean>;
  _unsubscribe = new Subject<void>();
  @ViewChild('inputClick2Call') inputClick2Call: ElementRef<HTMLInputElement>;

  constructor(
    private eventLogStateService: EventLogStateService,
    private callService: CallService,
    private countryService: CountryService,
    private fb: UntypedFormBuilder,
    private socketService: Socket,
    private sessionService: SessionService,
    private notifications: NotificationService,
    private eventLogService: EventLogService,
  ) {
    this.selectedCategory = this.categories[0];
    this.connectingToPbx$ = this.callService.connectingToPbx$;
    this.currentlyLoggedInOperator = this.sessionService.currentlyLoggedOperator;
    this.operatorId = this.currentlyLoggedInOperator.id;
  }

  ngOnInit(): void {
    this.createClick2CallForm();
    this.getCountries();
    this.watchForPhoneNumberInput();
    this.listenForSocketEvents();
    this.getCalls(CallCategoryTypes.ALL);

    this.eventLogStateService.getGlobalState().subscribe((state: Partial<EventLogExtendedState>) => {
      this.selectedCall = state.call;
      this.eventLogService.setHighlightedEntityInEventLog(this.selectedCall);
    })
  }

  ngAfterViewInit(): void {
    this.inputClick2Call.nativeElement.focus();
  }

  resetPhoneNumber() {
    const selectedCountry = this.click2CallForm.get('country').value;
    if (selectedCountry) {
      const dialCode = selectedCountry.dialCode;
      this.click2CallForm.get('phoneNumber').setValue(dialCode);
    } else {
      this.click2CallForm.get('phoneNumber').setValue('');
    }

    this.isEnterPressed = false;
  }

  getCalls(category: CallCategoryTypes) {
    this.isFetchingCalls = true;
    this.socketService.emit(PbxEvents.CH_CALLS, {
      category,
      operatorId: this.operatorId,
    } as ChCallsDto);
  }

  listenForSocketEvents() {
    this.socketService
      .fromEvent<Call[]>(PbxEvents.CH_CALLS)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((calls: Call[]) => {
        this.calls = calls || [];
        this.sortCallsByCreatedAt();
        this.isFetchingCalls = false;
      });

    this.socketService
      .fromEvent<Call>(PbxEvents.CH_CALL_CREATED)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((call: Call) => {
        this.socketService.emit(PbxEvents.CH_CALL_CREATED, {
          lastCallId: this.calls[0]?.id,
          category: this.selectedCategory.id,
          operatorId: this.operatorId,
        } as ChCallCreatedDto);
      });

      this.socketService
      .fromEvent<Call>(PbxEvents.CH_NEW_CALL)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((newCall: Call) => {
        if (this.selectedCategory.id === CallCategoryTypes.ALL) {
          this.addOrUpdateCall(newCall);
        }
        this.sortCallsByCreatedAt();
      });

    this.socketService
      .fromEvent<Call>(PbxEvents.CH_UPDATED_CALL)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((updatedCall: Call) => {
        this.addOrUpdateCall(updatedCall);
        this.sortCallsByCreatedAt();
      });
  }

  addOrUpdateCall(call: Call) {
    const index = this.calls?.findIndex((c: Call) => c.id === call.id);
    if (index === -1) {
      if (this.calls?.length >= 10) {
        this.calls.pop();
      }
      this.calls.unshift(call);
    } else {
      this.calls[index] = call;
    }
  }

  sortCallsByCreatedAt() {
    this.calls.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }

  navigateToCallsHistory() {
    const url = `/#/communication/calls-history?category=${this.selectedCategory?.id}`;

    window.open(url, '_blank');
  }

  navigateCallToNewTab(call: Call) {
    const url = `/#/home?callId=${call.id}`;

    window.open(url, '_blank');
  }

  openRightDrawer(call: Call) {
    let parsedNumber: PhoneNumber;

    if (call.phoneNumber && call.phoneNumber !== this.hiddenNumber) {
      parsedNumber = parsePhoneNumberFromString(
        call.phoneNumber,
        'BG' as CountryCode
      );
    }

    if (parsedNumber) {
      const country = this.findCountryByDialCode(
        '+' + parsedNumber.countryCallingCode
      );

      this.click2CallForm.patchValue({
        phoneNumber: parsedNumber.number,
        country: country,
      });
    }

    this.eventLogStateService.setState(
      {
        mode: EventLogMode.PBX,
        historyType: EventLogHistoryType.PHONE,
        subheader: EventLogSubheader.PBX,
        phoneNumber: call.phoneNumber,
        call: call,
      },
      { openDrawer: true }
    );

    this.eventLogService.setHighlightedEntityInEventLog(call);
  }

  onClick2Call(): void {
    const phoneNumberInput = this.click2CallForm.get('phoneNumber').value.replace(/\s/g, "");
    const countryCode = this.click2CallForm.get('country')?.value.code;
    this.isEnterPressed = false;

    if (phoneNumberInput) {
      const phoneNumber = parsePhoneNumberFromString(phoneNumberInput, countryCode as CountryCode);

      const formattedPhoneNumber = this.eventLogStateService.formatPhoneNumber(phoneNumber.formatInternational()).replace(/\s/g, "");
      this.eventLogStateService.setState({
        mode: EventLogMode.PBX,
        phoneNumber: formattedPhoneNumber,
        historyType: EventLogHistoryType.PHONE,
      }, { openDrawer: true });

      this.callService.call(formattedPhoneNumber);
    } else {
      this.notifications.showLocalizedSnackbarNotification({
        notificationText: 'pbx.enterValidPhoneNumber',
      });
    }
  }

  watchForPhoneNumberInput() {
    this.click2CallForm.get('phoneNumber').valueChanges.subscribe((value) => {
      if (!value) {
        this.matchingCountries = this.countries;
        this.click2CallForm.get('country').setValue(null, { emitEvent: false });
        return;
      }

      const asYouType = new AsYouType({ defaultCountry: 'BG' });
      asYouType.input(value);
      const dialCode = asYouType.getCallingCode();
      const country = this.findCountryByDialCode('+' + dialCode);
      if (country) {
        const formattedNumber = asYouType.getNumber();

        this.click2CallForm
          .get('country')
          .setValue(country, { emitEvent: false });
        this.click2CallForm
          .get('phoneNumber')
          .setValue(formattedNumber?.number || value, { emitEvent: false });
        this.matchingCountries = [];
      } else {
        this.click2CallForm.get('country').setValue(null, { emitEvent: false });
        this.matchingCountries = this.findCountriesByPartialDialCode(value);
      }
    });
  }

  findCountriesByPartialDialCode(partialDialCode: string): Country[] {
    return this.countries.filter((country: Country) =>
      country.dialCode.startsWith(partialDialCode)
    );
  }

  createClick2CallForm() {
    this.click2CallForm = this.fb.group({
      phoneNumber: [''],
      country: [null],
    });
  }

  setDefaultCountryDialCode() {
    const defaultCountry = this.countries.find(
      (country: Country) => country.code === 'BG'
    );

    if (defaultCountry) {
      this.click2CallForm.patchValue({
        phoneNumber: defaultCountry.dialCode,
        country: defaultCountry,
      });
    }
  }

  findCountryByDialCode(phoneNumber: string): Country {
    return this.countries.find((country: Country) =>
      phoneNumber.startsWith(country.dialCode)
    );
  }

  getCountries() {
    this.countryService
      .getCountries()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((countries: Country[]) => {
        this.countries = countries;

        this.setDefaultCountryDialCode();
      });
  }

  selectCountry(country: Country) {
    this.click2CallForm.get('phoneNumber').setValue(country.dialCode);
    this.click2CallForm.get('country').setValue(country);
    this.matchingCountries = [];
  }

  onCountryChange(country: Country) {
    this.click2CallForm.get('phoneNumber').setValue(country.dialCode);
  }

  onEnterPress() {
    this.isEnterPressed = true;

    this.inputClick2Call.nativeElement.blur();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
