import { Component, Input, OnChanges } from '@angular/core';
import { ClientService } from '@app/core/services/client.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { Client } from '@app/core/types/client';
import { CombinedStatusNameType, VERIFICATION_STATUS_COMBINED_STYLE_MAP, VERIFICATION_STATUS_STYLE_MAP, VerificationStatus, VerificationStatusNames, VerificationStatusReasons } from '@app/core/verification-status-type';
import { VerificationStatusEditComponent } from '@app/main/client/client-edit/verification-status-edit/verification-status-edit.component';
import { filter, forkJoin, takeUntil } from 'rxjs';
import { IdCardVerificationStatusEditComponent } from '../id-card-verification-status-edit/id-card-verification-status-edit.component';
import { IdentityVerificationStatus } from './verification.type';
import { StyleConfigMap } from '@app/config/credit-config';

@Component({
  selector: 'itfg-id-card-verification-status',
  templateUrl: './id-card-verification-status.component.html',
  styleUrl: './id-card-verification-status.component.scss'
})
export class IdCardVerificationStatusComponent implements OnChanges {
  @Input()
  client: Client;

  VERIFICATION_STATUS_STYLE_MAP: typeof VERIFICATION_STATUS_STYLE_MAP = VERIFICATION_STATUS_STYLE_MAP;

  displayText: string = '';

  combinedStatus: { name: string, reasons: string[] } = { name: '', reasons: [] };

  allowedReasons: VerificationStatusReasons[] = [];


  styleMap: StyleConfigMap = VERIFICATION_STATUS_COMBINED_STYLE_MAP;

  @Input()
  compactView = false;

  @Input()
  displayLabel = true;

  constructor(
    private dialogService: DialogProviderService,
    private clientService: ClientService,
  ) {
    console.log('opaaaaaaaaaa', this.client)
  }

  ngOnChanges() {
    if (this.client && this.client.faceStatus && this.client.idCardStatus) {
      this.mapStatus();
    }
  }

  mapStatus() {
    const { faceStatus, idCardStatus } = this.client;
    const verification = new IdentityVerificationStatus(faceStatus, idCardStatus);
    this.combinedStatus = verification.status;
  }

  editVerificationStatus() {
    const verificationType = 'face';
    forkJoin({
      faceStatusList: this.clientService.getVerificationStatuses$('face'),
      idCardStatusList: this.clientService.getVerificationStatuses$('id-card')
    }).subscribe(({ faceStatusList, idCardStatusList }) => {
      this.dialogService
        .open(IdCardVerificationStatusEditComponent, {
          data: {
            type: verificationType,
            civilId: this.client?.civilId,
            brandCode: this.client?.brand?.code,
            status,
            verificationStatusList: idCardStatusList,
            combinedStatus: this.combinedStatus,
            faceStatusList,
            idCardStatusList
          },
          autoFocus: false,
          restoreFocus: false,
          minWidth:'350px'
        })
        .afterClosed()
        .pipe(filter(success => !!success))
        .subscribe((success: boolean) => {
          console.log(success);
        });
    })
  }

}
