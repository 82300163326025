<button
  tabindex="-1"
  matSuffix
  type="button"
  mat-icon-button
  [disabled]="!client?.id && !contactId || !currentlyLoggedOperator?.phoneExtension || (['CALLS_ADD'] | isUnauthorized | async)"
  matTooltip="{{ 'pbx.actions.call' | translate }}"
  matTooltipPosition="above"
  (click)="makeCall()"
>
  <mat-icon>phone</mat-icon>
</button>
