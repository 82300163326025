import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService } from '@app/core/services/client.service';
import { VerificationStatusEditComponent } from '@app/main/client/client-edit/verification-status-edit/verification-status-edit.component';
import { VerificationStatusReasonToTranslationKeyPipe } from '../../pipes/verification-status-reason-to-translation-key.pipe';
import { CombinedStatusNameType, VERIFICATION_STATUS_COMBINED_STYLE_MAP, VERIFICATION_STATUS_STYLE_MAP, VerificationStatus, VerificationStatusNames, VerificationStatusReasons, VerificationStatusType } from '@app/core/verification-status-type';
import { concat, last, finalize } from 'rxjs';

@Component({
  selector: 'itfg-id-card-verification-status-edit',
  templateUrl: './id-card-verification-status-edit.component.html',
  styleUrl: './id-card-verification-status-edit.component.scss'
})
export class IdCardVerificationStatusEditComponent implements OnInit {
  public statusForm: UntypedFormGroup;
  public status: VerificationStatus;
  statusChangeOptions: string[];
  reasonFilter = new UntypedFormControl();
  statusReasonsList: string[] = [];
  fullReasonlist: Array<string>;
  combinedStatus: { name: CombinedStatusNameType, reason: VerificationStatusReasons } = null;
  styleMap: any;
  isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VerificationStatusEditComponent>,
    private clientService: ClientService,
    public formBuilder: UntypedFormBuilder,
    public verificationStatusReasonToTranslationKey: VerificationStatusReasonToTranslationKeyPipe
  ) {
    this.combinedStatus = this.data.combinedStatus;
    this.statusChangeOptions = [
      CombinedStatusNameType.VALID,
      CombinedStatusNameType.INVALID,
      CombinedStatusNameType.PENDING_ID_FRONT,
      CombinedStatusNameType.PENDING_FILES,
      CombinedStatusNameType.PENDING_AUTO,
      CombinedStatusNameType.PENDING_VALIDATION,
    ];
    this.statusForm = this.createForm();
    this.styleMap = VERIFICATION_STATUS_COMBINED_STYLE_MAP;
  }

  ngOnInit(): void {
  }

  createForm() {
    return this.formBuilder.group({
      status: [{ value: this.combinedStatus.name, disabled: false }],
    });
  }

  onSubmit(event: any) {
    this.isLoading = true;
    const { face, idCard } = this.getStatusChange(this.statusForm.get('status').value);
    concat(
      this.clientService.changeVerificationStatus$(this.data.civilId, 'id-card', this.data.brandCode, idCard),
      this.clientService.changeVerificationStatus$(this.data.civilId, 'face', this.data.brandCode, face),
    )
    .pipe(
      last(),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.dialogRef.close(true);
      this.clientService.onClientDataChange.next(res);
    });
  }

  getStatusChange(key: CombinedStatusNameType) {
    const map = {
      [CombinedStatusNameType.VALID]: {
        face: { status: VerificationStatusNames.VALID, reason: VerificationStatusReasons.FACE_VERIFIED },
        idCard: { status: VerificationStatusNames.VALID, reason: VerificationStatusReasons.DOCUMENT_VERIFIED },
      },
      [CombinedStatusNameType.PENDING_AUTO]: {
        face: { status: VerificationStatusNames.PENDING_AUTO, reason: null },
        idCard: { status: VerificationStatusNames.PENDING_AUTO, reason: null },
      },
      [CombinedStatusNameType.PENDING_ID_FRONT]: {
        face: { status: VerificationStatusNames.PENDING_MANUAL, reason: VerificationStatusReasons.UPLOADED },
        idCard: { status: VerificationStatusNames.PENDING_MANUAL, reason: VerificationStatusReasons.RETRY },
      },
      [CombinedStatusNameType.PENDING_FILES]: {
        face: { status: VerificationStatusNames.PENDING_MANUAL, reason: VerificationStatusReasons.RETRY },
        idCard: { status: VerificationStatusNames.PENDING_MANUAL, reason: VerificationStatusReasons.RETRY },
      },
      [CombinedStatusNameType.PENDING_VALIDATION]: {
        face: { status: VerificationStatusNames.PENDING_MANUAL, reason: VerificationStatusReasons.UPLOADED },
        idCard: { status: VerificationStatusNames.PENDING_MANUAL, reason: VerificationStatusReasons.UPLOADED },
      },
      [CombinedStatusNameType.INVALID]: {
        face: { status: VerificationStatusNames.INVALID, reason: null },
        idCard: { status: VerificationStatusNames.INVALID, reason: null },
      },
    }

    return map[key];
  }
}
