<div>
  <div class="edit-screen-header"
       layout="row"
       layout-wrap
       layout-align="space-between">
    <div flex-gt-md="33"
         flex-gt-sm="50"
         flex="50"
         layout="row"
         layout-align-gt-md="space-between end"
         layout-align="space-between end">
      <span class="edit-screen-title"
            [translate]="'communication.notesAgreements'">Notes / Agreements</span>
    </div>
    <div flex-gt-md="33"
         flex-gt-sm="50"
         flex="50"
         layout="row"
         layout-align-gt-md="end end"
         layout-align="end end">
      <div class="toggle-right-scrollbar-fix"
           *ngIf="credit">
        <mat-slide-toggle labelPosition="before"
                          [disabled]="('CONVERSATION_READ_ALL' | isUnauthorized | async)"
                          [(ngModel)]="includeAllConversations"
                          (change)="onNotesModeChange($event)">
          <span [translate]="'communication.showAll'">Show All</span>
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <mat-card appearance="outlined">
    <mat-card-content>
      <!-- <ng-template tdLoading="conversationListLoading"
                   tdLoadingType="circle"
                   tdLoadingMode="indeterminate"
                   tdLoadingStrategy="overlay"
                   tdLoadingColor="accent"> -->
      <itfg-data-table [data]="notesPage?.content"
                       [columns]="notesColumns">
        <ng-template itfgDataTableTemplate="creditId"
                     let-creditId="value"
                     let-row="row"
                     let-column="column">
          <div [itfgHighlightDataTableRow]="includeAllConversations && (creditId === credit.id)">
            <a target="_blank"
               [routerLink]="['/credits', creditId]">{{ creditId }}</a>
          </div>
        </ng-template>
        <ng-template itfgDataTableTemplate="call"
                     let-call="value">
          <div *ngIf="call?.id">
            <div flex
                 layout="row"
                 layout-align="start center">
              <itfg-call-direction-and-status-icon [call]="call"></itfg-call-direction-and-status-icon>
              {{ call.phoneNumber | formatToBulgarianPhoneNumber }}
            </div>
          </div>
          <!-- <ng-template #noCall>
            <span>Липсва разговор</span>
          </ng-template> -->
        </ng-template>
        <ng-template itfgDataTableTemplate="operatorId"
                     let-operatorId="value"
                     let-row="row"
                     #contactType>
          <a routerLink="/administration/administrators/{{operatorId}}"
             target="_blank">{{( administrators | itfgAdminIdToName:operatorId:false )}}</a>
        </ng-template>
        <ng-template itfgDataTableTemplate="content"
                     let-content="value"
                     let-row="row"
                     let-column="column">
          <div>
            <span>{{ content }}</span>
          </div>
        </ng-template>
        <ng-template itfgDataTableTemplate="topics"
                     let-topics="value">
          <div *ngIf="topics?.length > 0">
            <span class="italic">{{ topics | itfgFormatTopics }}</span>
          </div>
          <!-- <ng-template #noTopics>
            <span>Липсват теми</span>
          </ng-template> -->
        </ng-template>
        <ng-template itfgDataTableTemplate="createdAt"
                     let-createdAt="value">
          <itfg-date-format [date]="createdAt"></itfg-date-format>
        </ng-template>
        <ng-template itfgDataTableTemplate="actions"
                     let-row="row">
          <div layout="row">
            <button mat-icon-button
                    [disabled]="!(row | isNoteEditable)"
                    (click)="openEditNoteDialog(row)"
                    flex>
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </ng-template>
      </itfg-data-table>
      <mat-paginator #pagingBarResponsive
                     (page)="pageChanged($event)"
                     [pageIndex]="notesPage.number"
                     [length]="notesPage.totalElements"
                     [pageSize]="notesPage.size"
                     [pageSizeOptions]="[10, 20,30,100]"
                     showFirstLastButtons>
      </mat-paginator>
      <!-- </ng-template> -->
    </mat-card-content>
  </mat-card>
</div>
