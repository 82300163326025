import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CallService } from '@app/core/services/call.service';
import { SessionService } from '@app/core/services/session.service';
import { Client } from '@app/core/types/client';
import { Credit } from '@app/core/types/credit';
import { UserDetails } from '@app/core/types/user-details';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';
import {
  EventLogMode,
} from '@app/main/main-layout/right-drawer/shared/event-log.enums';

@Component({
  selector: 'itfg-click-to-call-button',
  templateUrl: './click-to-call-button.component.html',
  styleUrls: ['./click-to-call-button.component.scss'],
})
export class ClickToCallButtonComponent {
  @Input() control: UntypedFormControl;
  @Input() client: Client;
  @Input() credit: Credit;
  @Input() contactId: number;
  @Input() isInCreditEdit: boolean;
  @Input() isInClientEdit: boolean;
  currentlyLoggedOperator: UserDetails;

  constructor(
    private eventLogStateService: EventLogStateService,
    private callService: CallService,
    private sessionService: SessionService,
  ) { 
    this.currentlyLoggedOperator = this.sessionService.currentlyLoggedOperator;
  }

  openPbxComponent(phoneNumber: string) {
    this.eventLogStateService.setState(
      {
        mode: EventLogMode.PBX,
        client: this.client,
        phoneNumber: phoneNumber,
      },
      {
        openDrawer: true,
        isInCreditEdit: this.isInCreditEdit,
        isInClientEdit: this.isInClientEdit
      }
    );

  }

  makeCall() {
    const phoneNumber = this.control.value;
    const formattedPhoneNumber = this.eventLogStateService.formatPhoneNumber(phoneNumber);

    this.openPbxComponent(formattedPhoneNumber);
    this.callService.call(formattedPhoneNumber, this.isInCreditEdit, this.isInClientEdit);
  }
}
