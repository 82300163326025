import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CALL_DIRECTION_AND_STATUS_SVG_STYLE_MAP } from '@app/config/call-config';
import { CallService } from '@app/core/services/call.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SessionService } from '@app/core/services/session.service';
import { Call, CallDirection, CallIncomingStatuses, CallOutgoingStatuses, CallStatus } from '@app/core/types/call';
import { UserDetails } from '@app/core/types/user-details';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';

@Component({
  selector: 'itfg-call-direction-and-status-icon',
  templateUrl: './call-direction-and-status-icon.component.html',
  styleUrls: ['./call-direction-and-status-icon.component.scss'],
})
export class CallDirectionStatusIconComponent implements OnChanges {
  @Input() call: Call;
  @Input() phoneNumber: string;
  @Input() hiddenNumber: string;
  @Input() isInPbxComponent: boolean = false;
  @Output() callInitiated = new EventEmitter<string>();
  currentlyLoggedOperator: UserDetails;
  selectedStatus: CallStatus;
  callStatusOptions: any[] = Object.values(CallStatus);
  callStatuses: typeof CallStatus = CallStatus;
  callDirections: typeof CallDirection = CallDirection;
  callOutgoingTypes: any[] = Object.values(CallOutgoingStatuses);
  callIncomingTypes: any[] = Object.values(CallIncomingStatuses);

  public icon: string;
  public color: string;
  public class: string = '';

  constructor(
    private callService: CallService,
    private eventLogStateService: EventLogStateService,
    private notificationService: NotificationService,
    private sessionService: SessionService,
  ) {
    this.currentlyLoggedOperator = this.sessionService.currentlyLoggedOperator;
  }

  ngOnInit(): void {
    this.updateIconAndColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateIconAndColor();
    if (this.call && changes.call.previousValue !== changes.call.currentValue) {
      this.selectedStatus = null;
      this.callStatusOptions = this.call.direction === CallDirection.OUT ? this.callOutgoingTypes : this.callIncomingTypes;
    }
  }

  onSaveCallStatus() {
    const callStatus = Array.isArray(this.selectedStatus) ? this.selectedStatus[0] : this.selectedStatus;
    const callDto = { status: callStatus };

    const modifiedCallDto = { ...callDto, reference: this.call.id };
      this.callService.updateCall(this.call.id, modifiedCallDto).subscribe({
        next: (call: Call) => {
          this.eventLogStateService.setState({ 
            call: call,
            phoneNumber: call.phoneNumber,
          });
          this.notificationService.showLocalizedSuccessMessage({
            notificationText: 'pbx.successfullySetCallStatusMessage'
          });
        },
        error: (error: HttpErrorResponse) => {
          this.notificationService.showLocalizedErrorMessage({
            notificationText: 'pbx.unsuccessfullySetCallStatusMessage'
          });
        }
      });
  }

  updateIconAndColor(): void {
    const directionMap = CALL_DIRECTION_AND_STATUS_SVG_STYLE_MAP[this.call.direction];
    const statusConfig = directionMap[this.call.status];

    this.icon = statusConfig.icon;
    this.color = statusConfig.color;
    this.class = statusConfig.class || '';
  }
}
