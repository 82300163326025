<mat-card>
  <div class="card-container" layout="row" layout-align="space-between center">
    <div class="agent-section" flex="40">
      <mat-icon>support_agent</mat-icon>
      <span class="margin-top-8px">{{ administrators | itfgAdminIdToName:call.operatorId:true }}</span>
    </div>


    <mat-card-content class="content-section" flex="40">
      <span class="content-item">Тел. номер: {{ call.phoneNumber }}</span>
      <span class="content-item">Тип: {{ 'communication.callDirections.' + call.direction.toLowerCase() | translate
        }}</span>
      <span class="content-item">Статус: {{ (call.status | callStatusTranslationPipe) | translate }}</span>
    </mat-card-content>

    <div class="top-right-info" flex="20" layout="column" layout-align="start end">
      <div>
        <mat-icon matTooltip="{{ 'callLog.waitingTime' | translate }}"
          matTooltipPosition="left">hourglass_empty</mat-icon>
        {{ call.waitingTime | itfgDurationPipe }}
      </div>
      <div>
        <mat-icon matTooltip="{{ 'callLog.duration' | translate }}" matTooltipPosition="left">access_time</mat-icon>
        <span>
          {{ (call.duration - call.waitingTime) | itfgDurationPipe }}
        </span>
      </div>
    </div>

  </div>
</mat-card>

<div class="timeline">
  <div *ngFor="let step of steps; let i = index">
    <div class="timeline-time">{{ step.date | itfgDateFormat }}</div>
    <div class="container right">
      <div class="circle-icon">
        <mat-icon fontSet="material-symbols-outlined" class="fill">{{ step.icon }}</mat-icon>
      </div>
      <div class="content">
        <span>{{ step.label }}</span>
        <div *ngIf="step?.details || step.topics?.length > 0" class="note">
          {{ step?.details }}
          <div *ngIf="step.topics?.length > 0" class="comment-topics">
            <mat-chip-list>
              <mat-chip class="topic-tag" *ngFor="let topic of step.topics">
                {{ topic }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div flex="100" layout="row" layout-align="end end">
    <button mat-raised-button color="primary" (click)="onNoClick()">
      {{ 'global.close' | translate }}
    </button>
  </div>
</div>