<div layout="row"
     layout-align="space-between center"
     mat-dialog-title
     flex>
  <span>Верификация на Самоличност</span>
  <button tabindex="-1"
          mat-icon-button
          class="close-button"
          [mat-dialog-close]="false">
    <mat-icon>clear</mat-icon>
  </button>
</div>

@if (!isLoading) {
<form [formGroup]="statusForm"
      (ngSubmit)="onSubmit($event)">
  <div layout="column"
       layout-wrap>

    <mat-form-field>
      <mat-select formControlName="status"
                  placeholder="{{ 'verification.selectStatus' | translate }}">

        <mat-select-trigger
        >
          <span layout="row"
                layout-align="start end"
                [matTooltip]="('verification.verificationStatusOptionsCombinedTooltip.' + statusForm.get('status').value.toLowerCase()) | translate"
                matTooltipPosition="above">
            <mat-icon [ngStyle]="{ color: styleMap[statusForm.get('status').value]?.backgroundColor }">
              {{ styleMap[statusForm.get('status').value]?.icon }}
            </mat-icon>
            <span>{{ "verification.verificationStatusOptionsCombined." + statusForm.get('status').value.toLowerCase() |
              translate }}</span>
          </span>
        </mat-select-trigger>

        <mat-option *ngFor="let statusName of statusChangeOptions"
                    [value]="statusName"
                    [matTooltip]="('verification.verificationStatusOptionsCombinedTooltip.' + statusName?.toLowerCase()) | translate"
                    matTooltipPosition="left"
                    >
          <mat-icon [ngStyle]="{ color: styleMap[statusName]?.backgroundColor }">
            {{ styleMap[statusName]?.icon }}
          </mat-icon>
          {{ "verification.verificationStatusOptionsCombined." + statusName.toLowerCase() | translate }}
        </mat-option>

      </mat-select>

      <mat-hint>{{('verification.verificationStatusOptionsCombinedTooltip.' + statusForm.get('status').value.toLowerCase()) | translate}}</mat-hint>

    </mat-form-field>

    <div mat-dialog-actions align="end">
      <button flex
              type="submit"
              color="primary"
              mat-raised-button>
        {{ 'global.save' | translate }}
      </button>
    </div>
  </div>
</form>
} @else {
<div layout="row"
     layout-align="center">
  <mat-spinner></mat-spinner>
</div>
}
