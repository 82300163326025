<div class="pbx" [ngStyle]="{ height: isExpansionListOpen ? 'calc(100vh - 77px)' : 'auto' }">
  <div class="decorated-heading">
    <span layout="row" layout-align="start center" class="column-gap-0">
      <div *ngIf="interactionMode === interactionModes.EXISTING_CALL; else noCall" layout="row"
        layout-align="center center">
        <!-- Call direction and status icon component -->
        <itfg-call-direction-and-status-icon [call]="call" [phoneNumber]="call.phoneNumber"
          [hiddenNumber]="hiddenNumber" [isInPbxComponent]="true"
          (callInitiated)="makeCall($event)"></itfg-call-direction-and-status-icon>
        <!-- Phone Number -->
        <span class="margin-left-8px">
          {{ call.phoneNumber === hiddenNumber ? ('communication.hiddenNumber' | translate) : call.phoneNumber }}
        </span>

        <!-- Connecting To PBX -->
        <mat-icon *ngIf="connectingToPbx$ | async" matTooltip="{{ 'pbx.callStages.CONNECTING' | translate }}"
          matTooltipPosition="below" fontSet="material-symbols-outlined"
          class="fill margin-left-8px">settings_phone</mat-icon>

        <!-- Call has ended -->
        <button *ngIf="!(connectingToPbx$ | async) && call" mat-icon-button
          [disabled]="callStage !== callStages.INIT || call.status === callStatuses.INITIATED || call.status === callStatuses.ONGOING || call.phoneNumber === hiddenNumber || (['CALLS_ADD'] | isUnauthorized | async)"
          (click)="makeCall(call.phoneNumber)" matTooltip="{{ 'pbx.actions.callAgain' | translate }}"
          matTooltipPosition="below">
          <mat-icon fontSet="material-symbols-outlined" class="fill">phone</mat-icon>
        </button>
      </div>

      <!-- Placeholder when there is no call -->
      <ng-template #noCall>
        <span>{{ 'pbx.title' | translate }}</span>
      </ng-template>
    </span>

    <span layout="row" layout-align="end center" class="column-gap-0 ">
      <button mat-icon-button class="flex-padding-margin-fix" [matMenuTriggerFor]="moreActions" onfocus="this.blur()">
        <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
      </button>
      <mat-menu #moreActions="matMenu">
        <!-- View client profile -->
        <button mat-menu-item (click)="fetchClient(phoneNumber)">
          <mat-icon fontSet="material-symbols-outlined" class="fill">account_circle</mat-icon>
          <span>{{ 'pbx.actions.viewProfile' | translate }}</span>
        </button>

        <!-- Send temp pass -->
        <button mat-menu-item (click)="sendTempPassBySms()" [disabled]="
            !clientFoundByMobile ||
            !clientFoundByMobile.mobileConfirmed ||
            (['SMS_SEND'] | isUnauthorized | async)
          ">
          <mat-icon fontSet="material-symbols-outlined" class="fill">vpn_key</mat-icon>
          <span>{{ 'pbx.actions.tempPassword' | translate }}</span>
        </button>

        <!-- Blacklist phone number -->
        <button mat-menu-item *ngIf="!phoneNumberBlacklisted" (click)="blacklistPhoneNumber()" [disabled]="
            !clientFoundByMobile ||
            (['BLACKLIST_READ'] | isUnauthorized | async)
          ">
          <mat-icon fontSet="material-symbols-outlined" class="fill">block</mat-icon>
          <span>{{ 'pbx.actions.block' | translate }}</span>
        </button>

        <!-- Edit blacklist -->
        <button mat-menu-item *ngIf="phoneNumberBlacklisted" (click)="editBlacklist()"
          [disabled]="!client || (['BLACKLIST_READ'] | isUnauthorized | async)">
          <mat-icon fontSet="material-symbols-outlined" class="fill" color="warn">block</mat-icon>
          <span>{{ 'pbx.actions.blocked' | translate }}</span>
        </button>
      </mat-menu>
      <button mat-icon-button (click)="exitCall()">
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>

  <div class="credit-context-div" layout="row" [ngStyle]="{
     'min-height': '0',
     flex: isExpansionListOpen ? '1' : 'none',
     'margin-bottom': isExpansionListOpen ? '16px' : '0'
  }"
    [ngClass]="{'button-visible': showSelectOpenedCreditBtn && !isExpansionListOpen && !eventLogStateService.isFetchingCredits}">
    <itfg-expansion-list class="full-width" [onExpansionListOpened]="onExpansionListOpened"
      [onExpansionListClosed]="onExpansionListClosed"></itfg-expansion-list>

    <button mat-icon-button
      *ngIf="showSelectOpenedCreditBtn && !isExpansionListOpen && !eventLogStateService.isFetchingCredits"
      matTooltip="Свържи отворения кредит" matTooltipPosition="above" (click)="addOpenedCreditToContext()">
      <mat-icon>link</mat-icon>
    </button>
  </div>

  <!-- Manual call -->
  <form *ngIf="interactionMode === interactionModes.MANUAL_CALL && !isExpansionListOpen" layout="row" layout-wrap
    layout-align="space-around center" [formGroup]="manualCallForm"
    class="manual-call-fields remove-mat-form-field-wrapper">
    <mat-form-field flex="50">
      <mat-select formControlName="phone" disableOptionCentering="true"
        placeholder="{{ 'global.phoneNumber' | translate }}">

        <!-- Client's phone number -->
        <mat-option *ngIf="openedCredit || client" [ngStyle]="{'font-weight': 'bold'}"
          [value]="openedCredit?.user?.mobile || client?.mobile"
          matTooltip="{{ 'communication.clientPhoneNumber' | translate }}" 
          [matTooltipPosition]="'left'">
          {{ openedCredit?.user?.mobile || client?.mobile }}
        </mat-option>

        <!-- Opened credit contacts -->
        <mat-option *ngFor="let contact of contacts" [value]="contact.phone"
          [matTooltip]="contact.type?.name + (contact.description ? ' - ' + contact.description : '')"
          [matTooltipPosition]="'left'">
          {{
          contact.phone
          }}
        </mat-option>

        <!-- Failed click2call phone number-->
        <mat-option *ngIf="failedClick2CallNumber" [value]="failedClick2CallNumber">
          {{ failedClick2CallNumber }}
        </mat-option>

        <!-- Inquiry phone number for information about the person -->
        <mat-option matTooltip="{{ 'communication.inquiryPhonenumber' | translate }}" [matTooltipPosition]="'left'"
          [value]="inquiryPhonenumber">
          {{ inquiryPhonenumber }}
        </mat-option>

        <!-- Option for hidden number -->
        <mat-option [value]="hiddenNumber">
          {{ 'communication.hiddenNumber' | translate }}
        </mat-option>

        <!-- Option to add new contact -->
        <mat-option [value]="'addContact'" [disabled]="!client">
          {{ 'pbx.actions.addContact' | translate }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'global.phoneNumber' | translate }}</mat-label>
    </mat-form-field>

    <mat-form-field flex="50">
      <mat-select formControlName="direction" placeholder="{{ 'filtering.names.callDirection' | translate }}">
        <mat-option *ngFor="let direction of callDirectionTypes" [value]="direction">
          {{ ('communication.callDirections.' + direction.toLowerCase()) | translate }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'filtering.names.callDirection' | translate }}</mat-label>
    </mat-form-field>

    <mat-form-field class="call-status-field" flex="100">
      <mat-select panelClass="call-status-width" formControlName="status" disableOptionCentering="true"
        placeholder="{{ 'filtering.names.callStatus' | translate }}">
        <mat-option *ngFor="let status of callStatusTypes" [value]="status">
          {{ ('communication.fullCallStatuses.' + status) | translate }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'filtering.names.callStatus' | translate }}</mat-label>
    </mat-form-field>
  </form>

  <!-- Note -->
  <div class="note-div" [hidden]="isExpansionListOpen">
    <itfg-note [openedCredit]="openedCredit" [selectedCredit]="selectedCredit" [call]="call" [client]="client"
      [mode]="NoteMode.PBX" [manualCallForm]="manualCallForm" (noteSavedAfterCallEnded)="onNoteSavedAfterCallEnded()"></itfg-note>
  </div>

  <!-- History types -->
  <div class="history-type-toggle-div">
    <mat-button-toggle-group hideSingleSelectionIndicator flex flex-layout="row" class="history-type-toggle"
      [ngModel]="historyType">
      <mat-button-toggle flex [disabled]="!openedCredit?.id" class="history-type-control"
        [value]="EventLogHistoryType.CREDIT" (click)="selectHistoryType(EventLogHistoryType.CREDIT)"
        matTooltip="{{ 'historyLog.types.creditLog' | translate }}" matTooltipPosition="below">
        <mat-icon fontSet="material-symbols-outlined" class="history-type-control-icon">credit_card</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle flex
        [disabled]="!phoneNumber || phoneNumber === hiddenNumber || phoneNumber === inquiryPhonenumber"
        class="history-type-control" [value]="EventLogHistoryType.PHONE"
        (click)="selectHistoryType(EventLogHistoryType.PHONE)"
        matTooltip="{{ 'historyLog.types.phoneLog' | translate }}" matTooltipPosition="below">
        <mat-icon fontSet="material-symbols-outlined" class="history-type-control-icon">smartphone</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>