import { Component, OnInit, Inject, ViewContainerRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InteractionMode } from '@app/core/types/interaction-mode';
import { Note } from '@app/core/types/note';
import { NoteMode } from '../note/note.types';
import { NoteService } from '@app/core/services/note.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'itfg-edit-note-dialog',
  templateUrl: './edit-note-dialog.component.html',
  styleUrls: ['./edit-note-dialog.component.scss'],
})
export class EditNoteDialogComponent implements OnInit, OnDestroy {
  public notes: Note[];
  public interactionMode: InteractionMode;
  public noteModes: typeof NoteMode = NoteMode;

  NoteMode = NoteMode;
  _unsubscribe: Subject<void> = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<EditNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { notes: Note[]; interactionMode: InteractionMode },
    public _viewContainerRef: ViewContainerRef,
    private noteService: NoteService,
  ) {
    this.notes = data.notes;
    this.interactionMode = data.interactionMode;
  }

  ngOnInit() {
    this.noteService.setNotesToEditCount(this.notes.length);

    this.noteService.notesToBeEditedCount
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((count: number) => {
        if (count === 0) { // Close dialog entirely if only a single note is editable after saving it
          this.dialogRef.close();
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
