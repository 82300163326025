<mat-card appearance="outlined">
  <mat-card-title>
    <span class="edit-screen-card-title"
          [translate]="'clients.profileData'">Profile data</span>
  </mat-card-title>
  <mat-card-content class="remove-mat-form-field-wrapper">
    <form [itfgPermissions]="['USER_WRITE']"
          [formGroup]="profileDataForm">
      <div layout="column">
        <div *ngIf="isNewItem"
             flex
             layout="row"
             layout-wrap>
          <mat-form-field flex>
            <mat-select matInput
                        formControlName="brand"
                        placeholder="{{ 'brands.chooseBrand' | translate }}"
                        [compareWith]="compareItemsById">
              <mat-option [disabled]="['USER_WRITE'] | isUnauthorized | async"
                          *ngFor="let brand of brandList"
                          [value]="brand">{{ brand.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                isNewItem && profileDataForm.get('brand').hasError('required')
              "
                       [translate]="'forms.required'">This field is required
            </mat-error>
          </mat-form-field>
        </div>
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center">
          <mat-form-field flex>
            <input matInput
                   itfgTrimInput
                   itfgPhonePrefix
                   type="text"
                   required
                   formControlName="mobile"
                   placeholder="{{ 'clients.mobilePhone' | translate }}" />
            <mat-label>{{ 'clients.mobilePhone' | translate }}</mat-label>
            <div matSuffix
                 layout="row"
                 layout-align="center center">
              <itfg-click-to-call-button *ngxPermissionsOnly="['CALLS_ADD']"
                                         class="input-suffix-button"
                                         [control]="profileDataForm.get('mobile')"
                                         [client]="client"
                                         [isInCreditEdit]="isInCreditEdit"
                                         [isInClientEdit]="isInClientEdit">
              </itfg-click-to-call-button>
              <itfg-search-in-event-log-button *ngIf="isInClientEdit || isInCreditEdit"
                                               class="input-suffix-button"
                                               [control]="profileDataForm.get('mobile')">
              </itfg-search-in-event-log-button>
              <button flex
                      type="button"
                      mat-icon-button
                      matTooltip="{{ 'clients.addPhoneToBlacklist' | translate }}"
                      [matTooltipDisabled]="isNewItem"
                      matTooltipPosition="above"
                      class="input-suffix-button add-toblacklist-flag-color"
                      *ngIf="!phoneIsInBlacklist"
                      [disabled]="
                  isNewItem || (['BLACKLIST_READ'] | isUnauthorized | async)
                "
                      (click)="addToBlacklist()">
                <mat-icon>not_interested
                </mat-icon>
              </button>
              <button flex
                      type="button"
                      mat-icon-button
                      matTooltip="{{ 'clients.editPhoneInBlacklist' | translate }}"
                      [matTooltipDisabled]="isNewItem"
                      matTooltipPosition="above"
                      class="input-suffix-button blacklisted-flag-color"
                      *ngIf="phoneIsInBlacklist"
                      [disabled]="isNewItem"
                      (click)="editInBlacklist()">
                <mat-icon>not_interested
                </mat-icon>
              </button>
              <mat-checkbox formControlName="mobileConfirmed"
                            #mobileConfirmed
                            matTooltip="{{
                  mobileConfirmed.checked
                    ? ('global.confirmed' | translate)
                    : ('global.notConfirmed' | translate)
                }}"
                            matTooltipPosition="above"
                            labelPosition="before">
              </mat-checkbox>
            </div>


            <mat-error *ngIf="profileDataForm.get('mobile').hasError('notBulgarianCode')"
                       [translate]="'forms.bulgarianPhoneCode'">Missing 359
            </mat-error>
            <mat-error *ngIf="profileDataForm.get('mobile').hasError('notFullNumber')"
                       [translate]="'forms.notFullNumber'">
              Not full number
            </mat-error>
            <mat-error *ngIf="
                profileDataForm.get('mobile').hasError('invalidCharacters')
              "
                       [translate]="'forms.invalidCharactersMobile'">Invalid characters
            </mat-error>
            <mat-error *ngIf="
                profileDataForm.get('mobile').hasError('tooManyCharacters')
              "
                       [translate]="'forms.mobileManyCharacters'">Too many characters
            </mat-error>
          </mat-form-field>
        </div>
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center"
             *ngxPermissionsOnly="'USER_READ_ALL'">
          <mat-form-field flex>
            <input matInput
                   itfgTrimInput
                   type="text"
                   required
                   formControlName="email"
                   placeholder="{{ 'global.email' | translate }}" />
            <mat-label>{{ 'global.email' | translate }}</mat-label>
            <div matSuffix
                 layout="row"
                 layout-align="center center">
            <button flex
                      type="button"
                      mat-icon-button
                      matTooltip="{{ 'clients.emailInSuppressionList_' + emailSuppressionDestination.reason | translate }}"
                      matTooltipPosition="above"
                      *ngIf="emailSuppressionDestination"
                      [ngClass]="emailSuppressionDestination.reason"
                      (click)="deleteSuppressionListDestination(emailSuppressionDestination)">
                <mat-icon color="warn" >warning</mat-icon>
              </button>
              <mat-checkbox flex
                            formControlName="emailConfirmed"
                            #emailConfirmed
                            matTooltip="{{
                  emailConfirmed.checked
                    ? ('global.confirmed' | translate)
                    : ('global.notConfirmed' | translate)
                }}"
                            labelPosition="before">
              </mat-checkbox>
            </div>
            <mat-error *ngIf="
                profileDataForm.get('email').hasError('email') ||
                profileDataForm.get('email').hasError('incorrectMailFormat')
              "
                       [translate]="'forms.emailNotValid'">Invalid email
            </mat-error>
          </mat-form-field>
        </div>
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center"
             *ngxPermissionsOnly="'USER_READ_ALL'">
          <mat-form-field flex>
            <img *ngIf="data.googleId"
                 matTooltip="Включен вход с Google"
                 class="google-icon"
                 matSuffix
                 src="../assets/images/google.svg">
            <img *ngIf="data.facebookId"
                 matTooltip="Включен вход с Facebook"
                 class="facebook-icon"
                 matSuffix
                 src="../assets/images/facebook.svg">
            <input matInput
                   readonly
                   type="text"
                   formControlName="clientId"
                   placeholder="{{ 'global.clientId' | translate }}" />
            <mat-label>{{ 'global.clientId' | translate }}</mat-label>
          </mat-form-field>
        </div>
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center"
             *ngxPermissionsOnly="'USER_READ_ALL'">
          <mat-form-field flex>
            <a target="_blank"
               matSuffix
               [href]="METABASE_IP_MATCHES_LINK"
               [style]="{ padding: '8px' }">
              <span *ngIf="ipInfo"
                    matTooltip="{{
                  ('countries.' + ipInfo?.country | translate) +
                    (ipInfo?.city ? ' - ' + ipInfo?.city : '')
                }}"
                    matSuffix
                    class="fi fi-{{ ipInfo?.country?.toLowerCase() }}"></span>
              <span *ngIf="isPrivateIp"
                    matTooltip="Частно IP"
                    matSuffix
                    class="fi fi-unknown"></span>
            </a>
            <input readonly
                   matInput
                   type="text"
                   [value]="lastLogin?.ip"
                   placeholder="{{ 'clients.ipAddress' | translate }}" />
            <mat-label>{{ 'clients.ipAddress' | translate }}</mat-label>
          </mat-form-field>
        </div>
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center"
             *ngxPermissionsOnly="'USER_READ_ALL'">
          <mat-form-field flex>
            <input flex
                   matInput
                   type="text"
                   [value]="'clients.pep' | translate"
                   readonly
                   placeholder="{{ 'clients.pep' | translate }}" />
            <mat-label>{{ 'clients.pep' | translate }}</mat-label>
            <mat-checkbox flex
                          matSuffix
                          #pep
                          formControlName="pep"
                          matTooltip="{{
                pep.checked
                  ? ('clients.pepAgreementNotConfirmed' | translate)
                  : ('clients.pepAgreementConfirmed' | translate)
              }}"
                          labelPosition="before">
            </mat-checkbox>
          </mat-form-field>
        </div>
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center"
             *ngxPermissionsOnly="'USER_READ_ALL'">
          <mat-form-field flex>
            <input flex
                   matInput
                   type="text"
                   [value]="'clients.loyaltyProgram' | translate"
                   readonly
                   placeholder="{{ 'clients.loyaltyProgram' | translate }}" />
            <mat-label>{{ 'clients.loyaltyProgram' | translate }}</mat-label>
            <mat-checkbox flex
                          matSuffix
                          #loyaltyEnabled
                          formControlName="loyaltyEnabled"
                          matTooltip="{{
                loyaltyEnabled.checked
                  ? ('clients.loyaltyEnabled' | translate)
                  : ('clients.loyaltyDisabled' | translate)
              }}"
                          labelPosition="before">
            </mat-checkbox>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
