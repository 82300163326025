import { CreditScore, CreditScoreModelNames, CreditScoreStatusType, SCORE_ICON_MAP } from './credit-score';

export class CreditScoreView extends CreditScore {
  displayColor: string;
  scoreInWords: string;
  rating: CreditScoreStatusType;
  ratingDisplayColor: string // color hex
  public STYLE_MAP = SCORE_ICON_MAP;

  constructor(partial?: Partial<CreditScore>) {
    super();
    Object.assign(this, partial);
    this.setRatingDisplay();
    if (!!this.rating) {
      this.ratingDisplayColor = this.STYLE_MAP[this.rating].color;
    }
    if (this.request_data && typeof this.request_data === 'string') {
      this.request_data = JSON.parse(this.request_data);
    }
  }

  setInternalRating() {
    if (this.score >= 0 && this.score <= 273) {
      this.rating = CreditScoreStatusType.RISK;
    } else if (this.score > 273 && this.score <= 359) {
      this.rating = CreditScoreStatusType.UNKNOWN;
    } else if (this.score > 359 && this.score <= 424) {
      this.rating = CreditScoreStatusType.AVERAGE;
    } else if (this.score > 424 && this.score <= 500) {
      this.rating = CreditScoreStatusType.GREAT;
    } else if (this.score > 500 && this.score <= 1000) {
      this.rating = CreditScoreStatusType.EXCELLENT;
    }
  }

  setInternalPaydayRating() {
    if (this.score >= 0 && this.score <= 473) {
      this.rating = CreditScoreStatusType.RISK;
    } else if (this.score > 473 && this.score <= 618) {
      this.rating = CreditScoreStatusType.UNKNOWN;
    } else if (this.score > 618 && this.score <= 715) {
      this.rating = CreditScoreStatusType.AVERAGE;
    } else if (this.score > 715 && this.score <= 793) {
      this.rating = CreditScoreStatusType.GREAT;
    } else if (this.score > 793 && this.score <= 1000) {
      this.rating = CreditScoreStatusType.EXCELLENT;
    }
  }

  setCentrycsRating() {
    if (this.score >= 0 && this.score < 362) {
      this.rating = CreditScoreStatusType.RISK;
    } else if (this.score >= 362 && this.score < 489) {
      this.rating = CreditScoreStatusType.UNKNOWN;
    } else if (this.score >= 489 && this.score < 566) {
      this.rating = CreditScoreStatusType.AVERAGE;
    } else if (this.score >= 566 && this.score < 656) {
      this.rating = CreditScoreStatusType.GREAT;
    } else if (this.score >= 656 && this.score <= 1000) {
      this.rating = CreditScoreStatusType.EXCELLENT;
    }
  }

  setInstallmentsLoanNewClientRating() {
    if (this.score >= 0 && this.score <= 115) {
      this.rating = CreditScoreStatusType.EXCELLENT;
    } else if (this.score >= 118 && this.score <= 279) {
      this.rating = CreditScoreStatusType.GREAT;
    } else if (this.score >= 292 && this.score <= 435) {
      this.rating = CreditScoreStatusType.AVERAGE;
    } else if (this.score >= 459 && this.score <= 554) {
      this.rating = CreditScoreStatusType.UNKNOWN;
    } else if (this.score >= 556 && this.score <= 1000) {
      this.rating = CreditScoreStatusType.RISK;
    }
  }
  setPaydayLoanNewClientRating() {
    if (this.score >= 0 && this.score <= 18) {
      this.rating = CreditScoreStatusType.EXCELLENT;
    } else if (this.score >= 18 && this.score <= 122) {
      this.rating = CreditScoreStatusType.GREAT;
    } else if (this.score >= 122 && this.score <= 543) {
      this.rating = CreditScoreStatusType.AVERAGE;
    } else if (this.score >= 543 && this.score <= 700) {
      this.rating = CreditScoreStatusType.UNKNOWN;
    } else if (this.score >= 700 && this.score <= 1000) {
      this.rating = CreditScoreStatusType.RISK;
    }
  }
  setInternalScoringRepearClient() {
    if (this.score >= 0 && this.score <= 135) {
      this.rating = CreditScoreStatusType.ONE;
    } else if (this.score >= 135 && this.score <= 317) {
      this.rating = CreditScoreStatusType.TWO;
    } else if (this.score >= 317 && this.score <= 581) {
      this.rating = CreditScoreStatusType.THREE;
    } else if (this.score >= 581 && this.score <= 921) {
      this.rating = CreditScoreStatusType.FOUR;
    } else if (this.score >= 921 && this.score <= 1389) {
      this.rating = CreditScoreStatusType.FIVE;
    } else if (this.score >= 1389 && this.score <= 1939) {
      this.rating = CreditScoreStatusType.SIX;
    } else if (this.score >= 1939 && this.score <= 2568) {
      this.rating = CreditScoreStatusType.SEVEN;
    } else if (this.score >= 2568 && this.score <= 3353) {
      this.rating = CreditScoreStatusType.EIGHT;
    } else if (this.score >= 3353 && this.score <= 4538) {
      this.rating = CreditScoreStatusType.NINE;
    } else if (this.score >= 4538) {
      this.rating = CreditScoreStatusType.TEN;
    }
  }
  setInstallmentsScoringRepeatClient() {
    if (this.score >= 0 && this.score <= 364) {
      this.rating = CreditScoreStatusType.ONE;
    } else if (this.score >= 364 && this.score <= 673) {
      this.rating = CreditScoreStatusType.TWO;
    } else if (this.score >= 673 && this.score <= 990) {
      this.rating = CreditScoreStatusType.THREE;
    } else if (this.score >= 990 && this.score <= 1338) {
      this.rating = CreditScoreStatusType.FOUR;
    } else if (this.score >= 1338 && this.score <= 1787) {
      this.rating = CreditScoreStatusType.FIVE;
    } else if (this.score >= 1787 && this.score <= 2334) {
      this.rating = CreditScoreStatusType.SIX;
    } else if (this.score >= 2334 && this.score <= 3079) {
      this.rating = CreditScoreStatusType.SEVEN;
    } else if (this.score >= 3079 && this.score <= 4044) {
      this.rating = CreditScoreStatusType.EIGHT;
    } else if (this.score >= 4044 && this.score <= 5454) {
      this.rating = CreditScoreStatusType.NINE;
    } else if (this.score >= 5454) {
      this.rating = CreditScoreStatusType.TEN;
    }
  }
  setRatingDisplay() {
    switch (this.model_name) {
      case CreditScoreModelNames.INTERNAL:
        this.setInternalRating();
        break;
      case CreditScoreModelNames.INTERNAL_PAYDAY:
        this.setInternalPaydayRating();
        break;
      case CreditScoreModelNames.CENTRYCS:
        this.setCentrycsRating();
        break;
      case CreditScoreModelNames.INSTALLMENTS_LOAN_NEW_CLIENT:
        this.setInstallmentsLoanNewClientRating();
        break;
      case CreditScoreModelNames.PAYDAY_NEW_CLIENT_2023:
        this.setPaydayLoanNewClientRating();
        break;
      case CreditScoreModelNames.INTERNAL_SCORING_REPEAT_CLIENT_2024:
        this.setInternalScoringRepearClient();
        break;
      case CreditScoreModelNames.INSTALLMENTS_LOAN_REPEAT_CLIENT_2024:
        this.setInstallmentsScoringRepeatClient();
        break;
    }
  }

}