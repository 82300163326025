import { FilterUIType, FilterValueListService, FilterConfigBaseType } from '.';
import { SearchOperations, SearchCriterium } from '../../../core/types/search-criterium';
import { FilterGroupType } from './filter-group-type';
import { Filter } from './filter';

export interface FilterConfigConstructorParam extends FilterConfigBaseType {
  path: string;
  type: FilterUIType;
  group?: FilterGroupType;
  availableOperations?: SearchOperations[];
  valueListService?: FilterValueListService;
  translationKey?: string;
  transformFilterCriteriumForApiCall?: (value: Filter) => SearchCriterium | SearchCriterium[];
}
