import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/lib/material.module';
import { CallStatusIconModule } from '@app/main/client-communications/call-status-icon/call-status-icon.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RightDrawerComponent } from './right-drawer.component';
import { EventLogComponent } from './event-log/event-log.component';
import { EventLogFilteringComponent } from './event-log/filtering/event-log-filtering.component';
import { isLogIssuePipe } from '@app/main/client/pipes/is-log-issue.pipe';
import { MatButtonModule } from '@angular/material/button';
import { PbxComponent } from './event-log/pbx/pbx.component';
import { MatListModule } from '@angular/material/list';
import { ExpansionListComponent } from './event-log/pbx/expansion-list/expansion-list.component';
import { CreditStatusIconModule } from '@app/main/credit/credit-status-icon/credit-status-icon.module';
import { NoteService } from '@app/core/services/note.service';
import { NoteComponent } from './event-log/notes/note/note.component';
import { EventLogNotesComponent } from './event-log/notes/event-log-notes.component';
import { ReminderComponent } from './event-log/notes/reminder/reminder.component';
import { PaymentPromisesComponent } from './event-log/notes/payment-promises/payment-promises.component';
import { CreditPipesModule } from '@app/main/credit/credit-pipes.module';
import { EditNoteDialogComponent } from './event-log/notes/edit-note-dialog/edit-note-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormatDurationPipe } from '@app/shared/pipes/duration.pipe';
import { CallEditDialogComponent } from './event-log/pbx/call-edit-dialog/call-edit-dialog.component';
import { EventLogAddEntityDisplayComponent } from './event-log/event-log-add-entity-display/event-log-add-entity-display.component';
import { FormatTopicsPipe } from '@app/shared/pipes/format-topics.pipe';
import { FormatTopicName } from '@app/shared/pipes/topic-separator-visualization.pipe';
import { GetTopicRootPipe } from '@app/shared/pipes/get-topic-root.pipe';
import { IsNoteEditablePipe } from '@app/shared/pipes/is-note-editable.pipe';
import { CallSummaryComponent } from '@app/main/client-communications/call-summary/call-summary.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    BrowserModule,
    MatIconModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatBadgeModule,
    CallStatusIconModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    CreditStatusIconModule,
    CreditPipesModule,
    MatDialogModule,
  ],
  declarations: [
    RightDrawerComponent,
    EventLogComponent,
    EventLogFilteringComponent,
    isLogIssuePipe,
    PbxComponent,
    NoteComponent,
    ExpansionListComponent,
    EventLogNotesComponent,
    ReminderComponent,
    PaymentPromisesComponent,
    EditNoteDialogComponent,
    CallEditDialogComponent,
    CallSummaryComponent,
    EventLogAddEntityDisplayComponent,
  ],
  providers: [NoteService, FormatDurationPipe, FormatTopicsPipe, FormatTopicName, GetTopicRootPipe, IsNoteEditablePipe],
  exports: [
    RightDrawerComponent,
    EventLogComponent,
    EventLogFilteringComponent,
    isLogIssuePipe,
    NoteComponent,
    PbxComponent,
    ExpansionListComponent,
    EditNoteDialogComponent,
    CallEditDialogComponent,
    CallSummaryComponent,
    EventLogAddEntityDisplayComponent,
  ],
})
export class RightDrawerModule {}
