export const cyrLatMap: { [key: string]: string } = {
  А: 'A', // Cyrillic А to Latin A
  В: 'B',
  Е: 'E',
  К: 'K',
  М: 'M',
  О: 'O',
  Р: 'P',
  С: 'C',
  Т: 'T',
  Х: 'X',
  а: 'A', // lowercase Cyrillic to uppercase Latin
  в: 'B',
  е: 'E',
  к: 'K',
  м: 'M',
  о: 'O',
  р: 'P',
  с: 'C',
  т: 'T',
  х: 'X',
};
