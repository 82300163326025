import { CombinedStatusNameType, VerificationStatus, VerificationStatusNames, VerificationStatusReasons } from "@app/core/verification-status-type"

class CombinedStatus { name: CombinedStatusNameType; reasons: string[] };

export class IdentityVerificationStatus {

  public status: CombinedStatus;

  private face: {
    valid: boolean,
    manual: boolean;
    pendingIdFront: boolean;
    pendingValidation: boolean;
  } = {
      valid: false,
      manual: false,
      pendingIdFront: false,
      pendingValidation: false,
    };

  private idCard: {
    valid: boolean,
    manual: boolean;
    pendingIdFront: boolean;
    pendingValidation: boolean;
  } = {
      valid: false,
      manual: false,
      pendingIdFront: false,
      pendingValidation: false,
    };

  public valid: boolean;
  public invalid: boolean;
  public pendingIdFront: boolean;
  public pendingFiles: boolean;
  public pendingValidation: boolean;
  public manual: boolean;
  public auto: boolean;
  public no_reason: boolean;

  constructor(
    private faceStatus: VerificationStatus,
    private idCardStatus: VerificationStatus
  ) {
    this.setVerification();
  }

  setVerification() {
    // Set valid
    this.face.valid = this.faceStatus.name === VerificationStatusNames.VALID;
    this.idCard.valid = this.idCardStatus.name === VerificationStatusNames.VALID;
    this.valid = this.idCard.valid && this.face.valid;

    // set invalid
    this.invalid = this.faceStatus.name === VerificationStatusNames.INVALID && this.idCardStatus.name === VerificationStatusNames.INVALID;
    this.no_reason = this.faceStatus.reason === null && this.idCardStatus.reason === null;

    // Set manual
    this.face.manual = this.faceStatus.name === VerificationStatusNames.PENDING_MANUAL;
    this.idCard.manual = this.idCardStatus.name === VerificationStatusNames.PENDING_MANUAL;
    this.manual = this.face.manual && this.idCard.manual;

    // Set auto
    this.auto = (this.faceStatus.name === VerificationStatusNames.PENDING_AUTO) || (this.idCardStatus.name === VerificationStatusNames.PENDING_AUTO);

    // Set pending file
    this.face.pendingIdFront = this.face.manual && this.faceStatus.reason !== VerificationStatusReasons.UPLOADED;
    this.idCard.pendingIdFront = this.idCard.manual && this.idCardStatus.reason !== VerificationStatusReasons.UPLOADED;
    this.pendingIdFront = this.face.pendingIdFront || this.idCard.pendingIdFront;
    this.pendingFiles = this.face.pendingIdFront && this.idCard.pendingIdFront;

    this.setStatus();
  }

  setStatus() {
    let status: CombinedStatusNameType;

    if (this.valid) {
      status = CombinedStatusNameType.VALID;
    } else if (this.invalid && this.no_reason) {
      status = CombinedStatusNameType.INVALID;
    } else if (this.auto) {
      status = CombinedStatusNameType.PENDING_AUTO
    } else if (this.pendingFiles) {
      status = CombinedStatusNameType.PENDING_FILES;
    } else if (this.pendingIdFront) {
      status = CombinedStatusNameType.PENDING_ID_FRONT;
    } else {
      status = CombinedStatusNameType.PENDING_VALIDATION;
    }

    const reasons = [];

    if (this.faceStatus.reason && !this.face.valid) {
      reasons.push(this.faceStatus.reason);
    }

    if (this.idCardStatus.reason && !this.idCard.valid) {
      reasons.push(this.idCardStatus.reason);
    }

    const filterList = [];

    const allFilesUploaded = [this.idCardStatus.reason, this.faceStatus.reason].every(entry => entry === VerificationStatusReasons.UPLOADED);
    if (!allFilesUploaded || status !== CombinedStatusNameType.PENDING_VALIDATION) {
      filterList.push(VerificationStatusReasons.UPLOADED);
    }

    this.status = {
      name: status,
      reasons: [...new Set(reasons.filter(r => !filterList.includes(r)))],
    };
  }

}