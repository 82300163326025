import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  EventLogAuditType,
  EventLogAuditOperationType,
} from '../types/event-log.type';
import { TranslateService } from '@ngx-translate/core';
import { ToCustomItfgCurrencyPipe } from '../../../app/main/credit/pipes/to-custom-itfg-currency.pipe';
import { DateFormat } from '../types/date-format';
import { StorageService } from './storage.service';
import { BrowserStorageType } from '../../../app/config/app-config';
import { CLIENT_LOG_TOGGLE } from '../../../app/config/credit-config';
import { FormatIbanPipe } from '../../../app/main/credit/pipes/format-iban.pipe';
import { ItfgDateFormatPipe } from '../../../app/shared/pipes/itfg-date-format.pipe';
import { ReportType } from '../types/report.type';
import { CreditScoreStatusType } from '../types/credit-score';
import { FormatDurationPipe } from '@app/shared/pipes/duration.pipe';
import { Administrator } from '../types/administrator';
import { Call, CallDirection, CallStatus } from '../types/call';
import * as _ from 'lodash-es';
import { FormatTopicsPipe } from '@app/shared/pipes/format-topics.pipe';
import { Credit } from '../types/credit';
import { Client } from '../types/client';

@Injectable({
  providedIn: 'root',
})
export class EventLogService {
  refreshEventLog = new Subject<void>();  
  private highlightedEntity = new BehaviorSubject<Call | Credit | Client | null>(null);
  private lastHighlightedEntity: Call | Credit | Client | null = null;
  public highlightedEntity$ = this.highlightedEntity.asObservable();

  readonly translationMap = {
    type: (type: EventLogAuditType) =>
      this.translateService.instant('historyLog.auditType.' + type),
    time: (time: string) => {
      return this.datePipe.transform(time);
    },
    operation: (operation: EventLogAuditOperationType) =>
      this.translateService.instant('historyLog.operationType.' + operation),
    editor: (editor: string, clientId: number) => {
      return editor
        ? editor
        : this.translateService.instant('historyLog.editorTemplate', {
          clientId,
        });
    },
  };

  readonly ENTITY_FIELD_MAP = {
    [EventLogAuditType.USER]: {
      id: { label: 'global.number' },
      fullName: { label: 'global.fullName' },
      civilId: { label: 'global.civilId' },
      mobile: { label: 'clients.mobilePhone' },
      email: { label: 'global.email' },
    },
    [EventLogAuditType.TRANSACTION]: {
      amount: {
        label: 'global.amount',
        format: (amount: number) => this.toCustomItfgCurrency.transform(amount),
      },
      direction: {
        label: 'payments.paymentDirection',
        format: (direction: string) =>
          direction ? this.translateService.instant('payments.directionType.' + direction) : direction,
      },
      type: {
        label: 'global.type',
        format: (type: string) =>
          this.translateService.instant('transaction.type.' + type),
      },
      description: { label: 'global.description' },
    },
    [EventLogAuditType.CREDIT]: {
      id: { label: 'global.numberSign' },
      principal: {
        label: 'global.principal',
        format: (amount: number) => this.toCustomItfgCurrency.transform(amount),
      },
    },
    [EventLogAuditType.CONTACTPHONE]: {
      phone: { label: 'global.phoneNumber' },
      description: { label: 'clients.notes' },
    },
    [EventLogAuditType.CCC]: {
      id: { label: 'global.number' },
    },
    [EventLogAuditType.USERDOCUMENT]: {
      id: { label: 'global.number' },
      name: { label: 'global.name' },
    },
    [EventLogAuditType.PAYMENTPROMISE]: {
      date: {
        label: 'communication.agreedUponPaymentDateAndAmount',
        format: date => this.datePipe.transform(date, DateFormat.DATE),
      },
      amount: {
        label: 'communication.agreedUponPaymentAmount',
        format: (amount: number) => {
          return this.toCustomItfgCurrency.transform(amount);
        },
      },
    },
    [EventLogAuditType.PAYMENTAGREEMENT]: {
      id: { label: 'global.numberSign' }
    },
    [EventLogAuditType.BANKACCOUNT]: {
      id: { label: 'global.number' },
      iban: {
        format: (iban: string) => this.formatIbanPipe.transform(iban),
      },
      verified: {
        label: 'global.verified',
        format: (value: boolean) =>
          this.translateService.instant(value ? 'global.yes' : 'global.no'),
      },
    },
    [EventLogAuditType.REPORT]: {
      type: {
        label: 'global.type',
        format: (type: ReportType) => {
          return this.translateService.instant('reports.types.' + type);
        },
      },
      source: {
        label: 'reports.source',
        format: (source: string) => {
          return this.translateService.instant('reports.sources.' + source);
        },
      },
    },
    [EventLogAuditType.USERLOGIN]: {
      ip: {
        label: 'clients.ipAddress',
      },
    },
    [EventLogAuditType.SCORE]: {
      model_name: {
        label: 'global.model'
      },
      rating: {
        label: 'global.score',
        format: (type: CreditScoreStatusType) => {
          return this.translateService.instant('score.statusType.' + type);
        },
      },
    },
    [EventLogAuditType.CALL]: {
      direction: {
        format: (direction: CallDirection) => {
          return direction ? this.translateService.instant('communication.callDirections.' + direction.toLowerCase()) : '';
        }
      },
      status: {
        format: (status: CallStatus) => {
          return status ? this.translateService.instant('communication.fullCallStatuses.' + status) : '';
        },
      },
      duration: {
        label: 'callLog.duration',
        format: (duration: string | number) => {
          return duration ? this.formatDurationPipe.transform(duration) : '';
        },
      },
      waitingTime: {
        label: 'callLog.waitingTime',
        format: (waitingTime: string | number) => {
          return waitingTime ? this.formatDurationPipe.transform(waitingTime) : '';
        }
      }
    },
    [EventLogAuditType.NOTE]: {
      content: {
        format: (content: string) => {
          return content
        }
      },
   },
  };

  readonly OPENING_BRACKET = '<';
  readonly CLOSING_BRACKET = '>';
  readonly OPENING_MARK_TAG = '<mark>';
  readonly CLOSING_MARK_TAG = '</mark>';
  public markTags = [this.OPENING_MARK_TAG, this.CLOSING_MARK_TAG];
  public html = '';
  public _clientLogToggled = false;
  public administrators: Administrator[] = [];
  storage: StorageService;
  openEventLog: Subject<boolean> = new Subject<boolean>();
  searchInEventLog: Subject<string> = new Subject<string>();

  constructor(
    private translateService: TranslateService,
    private toCustomItfgCurrency: ToCustomItfgCurrencyPipe,
    private datePipe: ItfgDateFormatPipe,
    private formatIbanPipe: FormatIbanPipe,
    private formatDurationPipe: FormatDurationPipe,
    public formatTopicsPipe: FormatTopicsPipe,
  ) {
    this.storage = new StorageService(BrowserStorageType.LocalStorage);
    this.clientLogToggled = this.storage.getItem(CLIENT_LOG_TOGGLE);
  }

  set clientLogToggled(value: any) {
    this.storage.setItem(CLIENT_LOG_TOGGLE, value);
    this._clientLogToggled = JSON.parse(value);
  }

  get clientLogToggled() {
    return this._clientLogToggled;
  }

  highlight({
    value,
    searchStr,
    searchText,
    exactMatch = true,
  }: {
    value: string;
    searchStr?: string;
    searchText?: string;
    exactMatch?: boolean;
  }) {
    if (!searchStr || !value) {
      return value;
    }
    value = value.toString();
    searchStr = searchStr.toString();

    let text = searchText ? searchText.toString() : value;
    text = text.replace(/(<([^>]+)>)/gi, '');
    const html = searchText || value;
    let matchedIndices = this.findMatchingIndexes(text, searchStr);

    if (matchedIndices.length === 0 && !exactMatch) {
      let matches = [];
      searchStr.split(/\s/).forEach(w => {
        this.findMatchingIndexes(text, w);
        const foundMatches = this.findMatchingIndexes(text, w);
        matches = matches.concat(foundMatches);
      });

      matchedIndices = matchedIndices.concat(matches);
      matchedIndices = matchedIndices.sort((a, b) => a.index - b.index);
    }

    if (matchedIndices.length === 0) {
      return value;
    }

    const stack = [];
    const htmlLength = html.length;
    let match = matchedIndices.shift();
    let counter = 0;
    let markedHtml = '';

    for (let i = 0; i < htmlLength; i++) {
      const char = html[i];
      const matchIndex = match && match.index;
      const matchLength = match && match.matchLength;
      const isOpeningBracket = this.isOpeningBracket(char);
      const isClosingBracket = this.isClosingBracket(char);
      const startPosReached: boolean = counter === matchIndex;
      const endPosReached: boolean = counter === matchIndex + matchLength - 1;
      const markMode =
        counter >= matchIndex && counter < matchIndex + matchLength;
      const stackEmpty = stack.length === 0;

      if (isOpeningBracket) {
        stack.push(char);
      } else if (isClosingBracket) {
        stack.shift();
      } else if (stackEmpty) {
        counter++;
      }

      if (markMode && stackEmpty && !isClosingBracket && !isOpeningBracket) {
        markedHtml += this.getMarkTag() + char + this.getMarkTag();
      } else {
        markedHtml += char;
      }

      if (endPosReached) {
        match = matchedIndices.shift();
      }
    }

    return markedHtml;
  }

  findMatchingIndexes(text: string, search: string) {
    const regex = new RegExp(this.escapeRegExp(search), 'gi');
    const indices = [];
    let result;

    while (search && (result = regex.exec(text)) !== null) {
      indices.push({
        index: result.index,
        matchLength: search.length,
      });
    }
    return indices;
  }

  isOpeningBracket(char: string) {
    return char === this.OPENING_BRACKET;
  }

  isClosingBracket(char: string) {
    return char === this.CLOSING_BRACKET;
  }

  escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  getMarkTag() {
    const currentTag = this.markTags.shift();
    this.markTags.push(currentTag);
    return currentTag;
  }

  italic(value: string) {
    return `<i>${value}</i>`;
  }

  div(value: string) {
    return `<div>${value}</div>`;
  }

  strikethrough(value: string) {
    return `<del>${value}</del>`;
  }

  setHighlightedEntityInEventLog(entity: Call | Credit | Client) {
    if (!entity) {
      this.highlightedEntity.next(null); 
      return;
    }

    if (this.lastHighlightedEntity?.id !== entity?.id) {
      this.lastHighlightedEntity = entity;
      this.highlightedEntity.next(entity);
    }
  }

  getHighlightedEntityInEventLog() {
    return this.highlightedEntity.getValue();
  }

  getAuditEntityConfig(auditType: EventLogAuditType, field: string) {
    return this.ENTITY_FIELD_MAP[auditType]
      ? this.ENTITY_FIELD_MAP[auditType][field]
      : {};
  }
}
