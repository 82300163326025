import { ValidationErrors, AbstractControl } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { cyrLatMap } from '../utils/cyrillic-latin-char-map';

export const IdentifierValidation = {
  onlyNumbers: onlyNumbers,
  onlyNumbersAndLatinLetters: onlyNumbersAndLatinLetters,
  containsDigits: containsDigits,
  containsLetters: containsLetters,
  isValidIban: isValidIban,
  isValidIdCardNumber: isValidIdCardNumber,
  isValidIdEmail: isValidIdEmail,
  isValidEgn: isValidEgn,
  bulgarianPhoneNumber: bulgarianPhoneNumber,
  isValididEgnEmailAndPhone: isValididEgnEmailAndPhone,
  isValidEik: isValidEik,
};

const bulgariaPhoneCode = '+359';
const phoneNumberLength = 9;

function onlyNumbers(control: UntypedFormControl): ValidationErrors {
  if (!control.value || control.value.length === 0) {
    return null;
  }

  const numbers = /^\d*$/;
  const result = numbers.test(control.value);
  return result ? null : { onlyNumbers: { value: control.value } };
}

function onlyNumbersAndLatinLetters(
  control: UntypedFormControl
): ValidationErrors {
  const numbersAndLattinLetters = /^(\d|[A-Za-z])*$/;
  const result = numbersAndLattinLetters.test(control.value);
  return result
    ? null
    : { onlyNumbersAndLatinLetters: { value: control.value } };
}

function containsDigits(control: UntypedFormControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  const controlContainsDigits = /[0-9]/.test(control.value);
  return controlContainsDigits
    ? null
    : { containsNumbers: { value: control.value } };
}

function containsLetters(control: UntypedFormControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  const controlContainsLetters = /[A-Za-z]/.test(control.value);
  return controlContainsLetters
    ? null
    : { containsLattinLetters: { value: control.value } };
}

function isValidIban(control: AbstractControl): ValidationErrors | null {
  const ibanMatchPattern = /^BG[0-9]{2}[A-Z]{4}[0-9]{6}[A-Z0-9]{8}$/.test(
    control.value
  );
  if (!control.value || control.value.length === 0) {
    return null;
  }
  return ibanMatchPattern ? null : { invalidCharacters: { value: control } };
}

function isValidIdEmail(control: AbstractControl): ValidationErrors | null {
  // tslint:disable-next-line:max-line-length
  const EMAIL_REGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (
    control.value !== null &&
    control.value !== '' &&
    (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))
  ) {
    return { incorrectMailFormat: true };
  }
  return null;
}

function bulgarianPhoneNumber(
  control: AbstractControl
): ValidationErrors | null {
  const currentPhone = control.value.substring(4, control.value.length);
  const currentPhoneContainsDigitsOnly = /^[0-9]*$/.test(currentPhone);

  if (!control.value.startsWith(bulgariaPhoneCode)) {
    return { notBulgarianCode: { value: control } };
  }

  if (control.value.length < bulgariaPhoneCode.length + phoneNumberLength) {
    return { notFullNumber: { value: control } };
  }

  if (control.value.length > bulgariaPhoneCode.length + phoneNumberLength) {
    return { tooManyCharacters: { value: control } };
  }

  return currentPhoneContainsDigitsOnly
    ? null
    : { invalidCharacters: { value: control } };
}

function isValidEgn(control: AbstractControl): ValidationErrors | null {
  const EGN_WEIGHTS = [2, 4, 8, 5, 10, 9, 7, 3, 6];

  if (!control.value) {
    return null;
  }

  if (control.value.length !== 10) {
    return { incorrectEgnFormat: true };
  }

  const year = Number(control.value.substr(0, 2));
  const mon = Number(control.value.substr(2, 2));
  const day = Number(control.value.substr(4, 2));

  if (mon > 40) {
    if (!isValidDate(day, mon - 40, year + 2000)) {
      return { incorrectEgnFormat: true };
    }
  } else if (mon > 20) {
    if (!isValidDate(day, mon - 20, year + 1800)) {
      return { incorrectEgnFormat: true };
    }
  } else {
    if (!isValidDate(day, mon, year + 1900)) {
      return { incorrectEgnFormat: true };
    }
  }

  const checkSum = Number(control.value.substr(9, 1));
  let egnSum = 0;
  for (let i = 0; i < 9; i++) {
    egnSum += Number(control.value.substr(i, 1)) * EGN_WEIGHTS[i];
  }

  let validCheckSum = egnSum % 11;
  validCheckSum %= 10;
  if (validCheckSum === 10) {
    validCheckSum = 0;
  }

  if (checkSum !== validCheckSum) {
    return { incorrectEgnFormat: true };
  }

  return null;
}

function isValidDate(d, m, y) {
  m = parseInt(m, 10) - 1;
  return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m, y);
}

function daysInMonth(m, y) {
  switch (m) {
    case 1:
      return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
}

function isValididEgnEmailAndPhone(
  control: AbstractControl
): ValidationErrors | null {
  const PHONE_REGEXP = /^\+359/;
  if (
    control.value &&
    isValidEgn(control) &&
    !PHONE_REGEXP.test(control.value) &&
    isValidIdEmail(control)
  ) {
    return { incorrectEgnMailAndPhone: true };
  }
  return null;
}

function isValidEik(control: AbstractControl): ValidationErrors | null {
  const eik = control.value;

  if (!eik || eik.length === 0) {
    return null;
  }

  if (!eik || !checkEikInput(eik)) {
    return { incorrectEik: true };
  }

  if (eik.length === 9 && calculateNinthDigitInEIK(eik) !== Number(eik[8])) {
    return { incorrectEik: true };
  } else if (
    eik.length === 13 &&
    calculateThirteenthDigitInEIK(eik) !== Number(eik[12])
  ) {
    return { incorrectEik: true };
  }

  return null;
}

function checkEikInput(eik: string): boolean {
  if (eik !== null && eik.length !== 9 && eik.length !== 13) {
    return false;
  }

  const charDigits = eik.split('');
  charDigits.forEach((char: any) => {
    if (Number.isNaN(char * 1)) {
      return false;
    }
  });

  return true;
}

const FIRST_SUM_9DIGIT_WEIGHTS = [1, 2, 3, 4, 5, 6, 7, 8];
const SECOND_SUM_9DIGIT_WEIGHTS = [3, 4, 5, 6, 7, 8, 9, 10];
const FIRST_SUM_13DIGIT_WEIGHTS = [2, 7, 3, 5];
const SECOND_SUM_13DIGIT_WEIGHTS = [4, 9, 5, 7];

function calculateNinthDigitInEIK(digits: string): Number {
  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum = sum + (digits[i] as any) * FIRST_SUM_9DIGIT_WEIGHTS[i];
  }
  const remainder = sum % 11;
  if (remainder !== 10) {
    return remainder;
  }
  // remainder= 10
  let secondSum = 0;
  for (let i = 0; i < 8; i++) {
    secondSum = secondSum + (digits[i] as any) * SECOND_SUM_9DIGIT_WEIGHTS[i];
  }
  const secondRem = secondSum % 11;
  if (secondRem !== 10) {
    return secondRem;
  }
  // secondRemainder= 10
  return 0;
}

function calculateThirteenthDigitInEIK(digits: string): Number {
  const ninthDigit = calculateNinthDigitInEIK(digits);
  if ((ninthDigit as any) !== digits[8]) {
    return 11;
  }
  // 9thDigit is a correct checkSum. Continue with 13thDigit
  let sum = 0;
  for (let i = 8, j = 0; j < 4; i++, j++) {
    sum = sum + (digits[i] as any) * FIRST_SUM_13DIGIT_WEIGHTS[j];
  }
  const remainder = sum % 11;
  if (remainder !== 10) {
    return remainder;
  }
  // remainder= 10
  let secondSum = 0;
  for (let i = 8, j = 0; j < 4; i++, j++) {
    secondSum = secondSum + (digits[i] as any) * SECOND_SUM_13DIGIT_WEIGHTS[j];
  }
  const secondRem = secondSum % 11;
  if (secondRem !== 10) {
    return secondRem;
  }
  // secondRemainder= 10
  return 0;
}

export function isValidIdCardNumber(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  let value = control.value.toUpperCase();

  // Convert Cyrillic to Latin
  for (const [cyrillic, latin] of Object.entries(cyrLatMap)) {
    value = value.replace(new RegExp(cyrillic, 'g'), latin);
  }

  // Check for remaining Cyrillic
  const hasUnmappableCyrillic = /[А-Яа-я]/.test(value);
  if (hasUnmappableCyrillic) {
    return {
      isValidIdCardFormat: {
        invalidChars: true,
      },
    };
  }

  // Validate pattern
  const isValidPattern = /^[A-Z0-9]{2}\d{7}$/.test(value);
  if (!isValidPattern) {
    return {
      isValidIdCardFormat: {
        invalidPattern: true,
      },
    };
  }

  // Update if transformed
  if (value !== control.value) {
    control.setValue(value, { emitEvent: false });
  }

  return null;
}
