import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CcrReportComponent } from './ccr-report/ccr-report.component';
import { MiReportComponent } from './mi-report/mi-report.component';
import { NssiReportComponent } from './nssi-report/nssi-report.component';
import { IdentityReportsService } from '../../core/services/identity-reports.service';
import { SharedModule } from '../../shared/shared.module';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import { MaterialModule } from '../../lib/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ChooseNssiTypeReportComponent } from './nssi-report/choose-nssi-type-report/choose-nssi-type-report.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UpdateClientComponent } from './update-client/update-client.component';
import { IdentityReportsPipesModule } from './identity-reports-pipes.module';
import { CreditPipesModule } from '../credit/credit-pipes.module';
import { CdkTableModule } from '@angular/cdk/table';
import { IdCardStatusComponent } from './mi-report/id-card-status/id-card-status.component';
import {
  IdCardStatusEditComponent
} from './mi-report/id-card-status/id-card-status-edit/id-card-status-edit.component';
import { IdCardImageListComponent } from './mi-report/id-card-image-list/id-card-image-list.component';
import { MatInputModule } from '@angular/material/input';
import { NssiPensionerReportComponent } from './nssi-report/nssi-pensioner-report/nssi-pensioner-report.component';
import { NssiStandaloneComponent } from './nssi-standalone/nssi-standalone.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MiStandaloneComponent } from './mi-standalone/mi-standalone.component';
import { MiCardComponent } from './mi-report/mi-card/mi-card.component';
import { NoReportsInfoComponent } from './no-reports-info/no-reports-info.component';
import { MaritalStatusReportComponent } from './marital-status-report/marital-status-report.component';
import { CompensationReportComponent } from './compensation-report/compensation-report.component';
import { ChooseCompensationTypeReportComponent } from './compensation-report/choose-compensation-type-report/choose-compensation-type-report.component';
import { ClientDifferencesButtonComponent } from './nssi-report/nssi-report-client-differences-button/client-differences-button.component';
import { RouterModule } from '@angular/router';
import { Nssi7SummaryComponent } from '@app/main/identity-reports/nssi-report/nssi7-report/nssi7-summary/nssi7-summary.component';
import {
  NssiAutomationDetailsComponent
} from '@app/main/identity-reports/nssi-report/nssi-automation-details/nssi-automation-details.component';
import { PersonDataReportComponent } from '@app/main/identity-reports/person-data-report/person-data-report.component';
import { AddressReportComponent } from '@app/main/identity-reports/address-report/address-report.component';
import { ChooseDateFromComponent } from '@app/main/identity-reports/address-report/choose-date-from/choose-date-from.component';
import { GraoReportsComponent } from '@app/main/identity-reports/grao-reports/grao-reports.component';
import { CcrStandaloneComponent } from './ccr-standalone/ccr-standalone.component';
import { ReportsInfoPanelComponent } from '@app/main/identity-reports/reports-info-panel/reports-info-panel/reports-info-panel.component';
import { ListBaseModule } from '../list-base/list-base.module';
import { ColumnModule } from '../column/column.module';
import { CcrReportSummaryComponent } from './ccr-report/ccr-report-summary/ccr-report-summary.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { CcrRequestsComponent } from './ccr-report/ccr-requests/ccr-requests.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { IdentityVerificationComponent } from './identity-verification/identity-verification.component';
import { IdCardFaceVerificationComponent } from './mi-report/id-card-face-verification/id-card-face-verification.component';
import { IdCardVerificationStatusComponent } from './mi-report/id-card-verification-status/id-card-verification-status.component';
import { IdCardVerificationStatusEditComponent } from './mi-report/id-card-verification-status-edit/id-card-verification-status-edit.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
@NgModule({
  declarations: [
    MiCardComponent,
    CcrReportComponent,
    CcrReportSummaryComponent,
    CcrStandaloneComponent,
    MiReportComponent,
    NssiReportComponent,
    ChooseNssiTypeReportComponent,
    ClientDifferencesButtonComponent,
    UpdateClientComponent,
    IdCardStatusComponent,
    IdCardStatusEditComponent,
    IdCardImageListComponent,
    IdCardFaceVerificationComponent,
    IdCardVerificationStatusComponent,
    IdCardVerificationStatusEditComponent,
    NssiPensionerReportComponent,
    NssiStandaloneComponent,
    MiStandaloneComponent,
    NoReportsInfoComponent,
    MaritalStatusReportComponent,
    CompensationReportComponent,
    ChooseCompensationTypeReportComponent,
    Nssi7SummaryComponent,
    NssiAutomationDetailsComponent,
    PersonDataReportComponent,
    AddressReportComponent,
    ChooseDateFromComponent,
    GraoReportsComponent,
    ReportsInfoPanelComponent,
    CcrRequestsComponent,
    IdentityVerificationComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DataTableModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedModule,
    MatSelectModule,
    FormsModule,
    MatDialogModule,
    IdentityReportsPipesModule,
    CreditPipesModule,
    DataTableModule,
    CdkTableModule,
    MatInputModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    RouterModule,
    ListBaseModule,
    ColumnModule,
    MatGridListModule,
    MatExpansionModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    CcrReportComponent,
    CcrRequestsComponent,
    MiReportComponent,
    NssiReportComponent,
    MaritalStatusReportComponent,
    CompensationReportComponent,
    PersonDataReportComponent,
    AddressReportComponent,
    ChooseDateFromComponent,
    GraoReportsComponent,
    ReportsInfoPanelComponent,
    IdentityVerificationComponent,
    IdCardVerificationStatusComponent,
    IdCardVerificationStatusEditComponent,
  ],
  providers: [IdentityReportsService, IdCardVerificationStatusComponent, IdCardVerificationStatusEditComponent],
})
export class IdentityReportsModule { }
