<mat-card appearance="outlined">
  <mat-card-title>
    <div layout="row" layout-align="space-between center">
      <span class="edit-screen-card-title" [translate]="'clients.documents'"
        >Title</span
      >
      <button
        [disabled]="'USER_DOCUMENT_WRITE' | isUnauthorized | async"
        type="button"
        mat-raised-button
        (click)="addDocumentField()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-title>
  <div class="progress-spinner-container">
    <mat-spinner
      class="progress-spinner"
      *ngIf="showLoader"
      strokeWidth="10"
      [diameter]="150"
    ></mat-spinner>
  </div>
  <mat-card-content *ngIf="!showLoader">
    <form
      novalidate
      [formGroup]="documentsDataForm"
      flex
      layout="row"
      layout-wrap
      layout-align="space-between center"
    >
      <div
        *ngFor="
          let document of listOfDocuments.slice().reverse();
          let i = index
        "
        flex="100"
        layout="row"
        layout-wrap
        layout-align="space-between center"
      >
        <div
          layout="row"
          layout-align="space-around start"
          class="column-gap-10"
          flex
          (click)="inputControl($event)"
        >
          <mat-form-field (click)="fileUpload.triggerUploadButton()" flex>
            <input
              matInput
              readonly
              [formControlName]="USER_DOCUMENT_TEXT + document.index"
              placeholder="{{ 'clients.documentOrImage' | translate }}"
              [value]="documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index]?.value?.name || ''"
              #fileResult
              itfgOverflowTooltip
              matTooltip
              [tooltipText]="fileResult.value"
              [tooltipPosition]="'above'"
            />
            <mat-label>
              @if(document?.type) {
                Лична карта - {{ document?.type }}
              } @else {
                {{ 'clients.documentOrImage' | translate }}
              }
            </mat-label>
            <mat-icon color="primary" matPrefix>{{ document.name | documentExtensionIcon }}</mat-icon>
            <a
              *ngIf="document && document.id"
              flex
              matSuffix
              mat-icon-button
              (click)="downloadDocumentClicked($event, document)"
              class="input-suffix-button"
              matTooltip="{{'global.download'|translate}}"
              matTooltipPosition="above"
            >
              <mat-icon>file_download</mat-icon>
            </a>
            <mat-hint
              class="override-color"
              *ngIf="
                documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index]
                  .errors &&
                documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index]
                  .errors.invalidField
              "
              [translate]="'clients.fileNameTooLong'"
              [translateParams]="{ value: MAX_FILE_NAME_LENGTH }"
              >Some of the fields are invalid
            </mat-hint>
            <mat-hint
              *ngIf="!document?.id"
              [translate]="'clients.selectDocumentInProperFormat'"
              >selectDocumentInProperFormat
            </mat-hint>
          </mat-form-field>
          <itfg-file-upload
            #fileUpload
            [hidden]="document?.id"
            (fileSelected)="onFileSelected($event, documentsDataForm.controls[USER_DOCUMENT_TEXT + document.index])"></itfg-file-upload>
          <button
            [disabled]="
              ('USER_DOCUMENT_DELETE' | isUnauthorized | async) &&
              document?.id
            "
            type="button"
            mat-icon-button
            (click)="deleteDocumentRow(document, i)"
            matTooltip="{{'global.delete'|translate}}"
            matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
