import * as ioClient from 'socket.io-client';
import { SocketIoConfig } from './socket-io.config';
import { StorageService } from '@app/core/services/storage.service';
import { APP_CONFIG } from '@app/config/app-config';
import { PbxEvents } from './types/pbx-event.enum';
import { LogRequestDto } from './types/log-request.dto';
import { Observable, share } from 'rxjs';

export class Socket {
  ioSocket: ioClient.Socket;
  storage: StorageService = new StorageService(APP_CONFIG.AUTH.STORAGE_TYPE);
  socketConnected: boolean = false;
  initializingSocketConnection: boolean = true;
  reconnectTimeout = null;
  reconnectAttempts = 0;
  maxReconnectAttempts = 6;
  reconnectDelay = 10000;

  constructor(config: SocketIoConfig) {
    const url: string = config.url;

    config.options.auth = cb => {
      cb({
        token: this.storage.getItem(APP_CONFIG.AUTH.SESSION_TOKEN_NAME) || '',
      });
    };
    this.ioSocket = ioClient.io(url, config.options);
    this.setEvents();
  }

  setEvents() {
    this.on('connect', this.onConnection.bind(this));
    this.on('connect_error', this.onConnectionError.bind(this));
    this.on('disconnect', this.onDisconnect.bind(this));
  }

  onConnection() {
    this.socketConnected = true;
    this.initializingSocketConnection = false;
    this.clearReconnectTimeout();
    this.reconnectAttempts = 0;
  }

  onConnectionError(error: Error) {
    console.error('Socket connection error:', error);
    this.socketConnected = false;
    this.scheduleReconnect();
  }

  onDisconnect() {
    console.error('Socket disconnected.');
    this.socketConnected = false;
    this.scheduleReconnect();
  }

  scheduleReconnect() {
    this.clearReconnectTimeout();

    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      this.reconnectTimeout = setTimeout(() => {
        this.ioSocket.connect();
      }, this.reconnectDelay);
    }
  }

  clearReconnectTimeout() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
  }

  on(eventName: string, callback: any) {
    this.ioSocket.on(eventName, callback);
  }

  emit(eventName: string, payload: any) {
    this.ioSocket.emit(eventName, payload);
  }

  emitUpdatedCallLogRequest(idLastCall: number | null, phoneNumber: string) {
    const payload: LogRequestDto = { idLastCall, phoneNumber };
    this.ioSocket.emit(PbxEvents.LOG_NEW, payload);
  }

  emitNewCallLogRequest(phoneNumber: string) {
    const payload = { phoneNumber };

    this.ioSocket.emit(PbxEvents.LOG_REQ, payload);
  }

  emitUpdateNotifications(operatorId: number) {
    this.ioSocket.emit(PbxEvents.NOTIF_REQ, operatorId);
  }

  joinPhoneNumberRoom(phoneNumber: string) {
    this.ioSocket.emit('joinPhoneNumberRoom', phoneNumber);
  }

  leavePhoneNumberRoom(phoneNumber: string) {
    this.ioSocket.emit('leavePhoneNumberRoom', phoneNumber);
  }

  checkForOngoingCall(operatorId: number) {
    this.ioSocket.emit(PbxEvents.CHECK_ONGOING_CALL, operatorId);
  }

  fromEvent<T>(eventName: string): Observable<T> {
    return new Observable<T>((observer: any) => {
      this.ioSocket.on(eventName, (data: T) => {
        observer.next(data);
      });
    }).pipe(share());
  }
}
