import { Component, ViewChild, Input } from '@angular/core';
import { EventLogFilterTemplate } from './event-log-filter-template';
import { EventLogFilteringService } from './event-log-filtering.service';
import {
  MatSlideToggle,
  MatSlideToggleChange
} from '@angular/material/slide-toggle';
import { MatSelectionList } from '@angular/material/list';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS
} from '@angular/material/tooltip';
import { EventLogService } from '@app/core/services/event-log.service';

const customTooltipOptions: Partial<MatTooltipDefaultOptions> = {
  disableTooltipInteractivity: true
};

@Component({
  selector: 'itfg-event-log-filtering',
  templateUrl: './event-log-filtering.component.html',
  styleUrls: ['./event-log-filtering.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipOptions }
  ]
})
export class EventLogFilteringComponent {
  _filterTemplate: EventLogFilterTemplate;
  selectedOptions: any;
  clientLogToggled: boolean;
  @ViewChild('slideToggle') slideToggle: MatSlideToggle;
  @ViewChild('subTemplatesSelectionList') subTemplatesSelectionList: MatSelectionList;
  @Input() logIssues: string[] = [];

  get filterTemplate() {
    return this._filterTemplate;
  }

  set filterTemplate(newTemplate: EventLogFilterTemplate) {
    this._filterTemplate = newTemplate;
  }

  constructor(
    public templateService: EventLogFilteringService, 
    private eventLogService: EventLogService
  ) {
    this.clientLogToggled = this.eventLogService.clientLogToggled;
    this._filterTemplate = this.templateService.filterTemplate;
  }

  allShown: boolean = false;

  updateAllShown(item: string) {
    this.filterTemplate.subTemplates.forEach(st => (st.shown = this.selectedOptions.includes(st.name)));
    this.allShown =
      this.filterTemplate.subTemplates != null &&
      this.filterTemplate.subTemplates.every(t => t.shown);
    this.templateService.setFilterTemplate(this.filterTemplate); // Save changes to localStorage
  }

  someComplete(): boolean {
    if (this.filterTemplate.subTemplates == null) {
      return false;
    }
    return (
      this.filterTemplate.subTemplates.filter(t => t.shown).length > 0 &&
      !this.allShown
    );
  }

  setAll(shown: boolean) {
    this.allShown = shown;
    if (this.filterTemplate.subTemplates == null) {
      return;
    }
    if (shown) {
      this.subTemplatesSelectionList.selectAll();
    } else {
      this.subTemplatesSelectionList.deselectAll();
    }
    this.filterTemplate.subTemplates.forEach(st => (st.shown = shown));
    this.templateService.setFilterTemplate(this.filterTemplate); // Save changes to localStorage
  }  

  shownFiltersLength() {
    return this.templateService.filterTemplate.subTemplates.filter(t => t.shown).length;
  }

  slideToggleChanged(event: MatSlideToggleChange) {
    this.slideToggle.checked = event.checked;
    this.templateService.slideToggleChanged(event);
  }

  
  trackTemplate(index: number, item: any) {
    return item.name;
  }

}
