import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Installment } from '../types/installment';
import { Observable } from 'rxjs';

@Injectable()
export class InstallmentService {
  constructor(public request: RequestService) { }

  getInstallmentList(creditId: number | string): Observable<Installment[]> {
    return this.request.get(['credits', creditId, 'installments']);
  }

  getInstallmentById(
    creditId: number | string,
    installmentId: number | string
  ): Observable<Installment> {
    return this.request.get([
      'credits',
      creditId,
      'installments',
      installmentId,
    ]);
  }

  updateInstallmentForfeitEnabled(creditId: number | string, installmentId: number | string, forfeitEnabled: boolean) {
    return this.request.post(['credits', creditId, 'installments', installmentId, 'forfeit-enabled'], {
      body: forfeitEnabled,
    });
  }
}
