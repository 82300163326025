import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Administrator } from '@app/core/types/administrator';
import { Call, CallStatus } from '@app/core/types/call';
import { Note } from '@app/core/types/note';
import { AdministratorExtensionToNamePipe } from '@app/shared/pipes/administrator-extension-to-name.pipe';
import { FormatDurationPipe } from '@app/shared/pipes/duration.pipe';
import { FormatTopicsPipe } from '@app/shared/pipes/format-topics.pipe';

@Component({
  selector: 'itfg-call-summary',
  templateUrl: './call-summary.component.html',
  styleUrls: ['./call-summary.component.scss'],
  providers: [AdministratorExtensionToNamePipe]
})
export class CallSummaryComponent implements OnInit {
  call: Call;
  administrators: Administrator[];
  steps: Array<{ label: string, date: Date | string, icon: string, details?: string, topics?: any }>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { call: Call, administrators: Administrator[] },
    public dialogRef: MatDialogRef<CallSummaryComponent>,
    private formatOperatorIdToName: AdministratorExtensionToNamePipe,
    private formatDurationPipe: FormatDurationPipe,
    private formatTopicsPipe: FormatTopicsPipe,
  ) {
    this.call = data.call;
    this.administrators = data.administrators;
  }

  ngOnInit(): void {
    this.initializeSteps();
  }

  initializeSteps() {
    this.steps = [];

    const callStartTime = new Date(this.call.updatedAt);
    callStartTime.setSeconds(callStartTime.getSeconds() - Number(this.call.duration));

    this.steps.push({
      label: 'Инициализиран',
      date: this.call.createdAt,
      icon: 'add_call'
    });

    if (this.call.createdAt !== this.call.updatedAt && this.call.externalId) {
      this.steps.push({
        label: 'Начало на звънене',
        date: callStartTime,
        icon: 'ring_volume'
      });
    }

    if (this.call.externalId && this.call.status === CallStatus.AGENT_CONNECTED || (this.call.status === CallStatus.ANSWER && this.call.createdAt !== this.call.updatedAt)) {
      const pickUpTime = new Date(callStartTime);
      pickUpTime.setSeconds(pickUpTime.getSeconds() + Number(this.call.waitingTime));

      const operatorName = this.formatOperatorIdToName.transform(this.administrators, this.call.operatorId);
      const formattedWaitingTime = this.formatDurationPipe.transform(this.call.waitingTime);
      this.steps.push({
        label: `Прието от ${this.call.status === CallStatus.AGENT_CONNECTED ? operatorName : 'клиента'} ${this.call.waitingTime > 0 ? ` след ${formattedWaitingTime}.` : ''}`,
        date: pickUpTime,
        icon: 'phone_in_talk'
      });
    }

    this.call.notes.forEach((note: Note) => {
      const operatorName = this.formatOperatorIdToName.transform(this.administrators, note.operatorId);
      const formattedTopics = Array.isArray(note.topics) && note.topics.length > 0
        ? this.formatTopicsPipe.transform(note.topics).split(', ')
        : [];

      this.steps.push({
        label: `Бележка от ${operatorName}`,
        date: note.createdAt,
        icon: 'edit_note',
        details: note.content,
        topics: formattedTopics
      });
    });

    if (this.call.updatedAt) {
      this.steps.push({
        label: 'Край',
        date: this.call.updatedAt,
        icon: 'call_end'
      });
    }

    this.steps.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
