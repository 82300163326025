import { Injectable } from "@angular/core";
import { ProductPlanEntry } from "@app/core/types/product-plan-entry";

interface InsurancePlanCacheEntry {
  insurancePlanData: ProductPlanEntry;
  insuranceAmount: number;
}

@Injectable({
  providedIn: 'root'
})
export class InsurancePlanCacheService {
  private cache = new Map<string, InsurancePlanCacheEntry>();

  constructor() {}

  getCachedInsurance(key: string): InsurancePlanCacheEntry | undefined {
    return this.cache.get(key);
  }

  storeInsurance(key: string, insurancePlanData: ProductPlanEntry, insuranceAmount: number): void {
    this.cache.set(key, { insurancePlanData, insuranceAmount });
  }

  isCached(key: string): boolean {
    return this.cache.has(key);
  }

  generateCacheKey(productPlanId: number, birthDate: string, principal: number, period: number): string {
    return `productPlanId-${productPlanId}-birthDate-${birthDate}-principal-${principal}-period-${period}`;
  }
}