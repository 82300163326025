<div [matTooltip]="('communication.callDirections.' + call.direction.toLowerCase() | translate) + ' - ' + ((call.status | callStatusTranslationPipe) | translate)"
     matTooltipPosition="below"
     [innerHTML]="icon | itfgSanitizeHtml"
     [ngClass]="['svg-icon', class]"
     [style.color]="color"
     [matMenuTriggerFor]="call.status === callStatuses.INITIATED && isInPbxComponent && call.operatorId === currentlyLoggedOperator.id ? callStatusActions : null"
     [class.clickable]="call.status === callStatuses.INITIATED && isInPbxComponent && call.operatorId === currentlyLoggedOperator.id">
</div>

<mat-menu #callStatusActions="matMenu"
          [overlapTrigger]="false"
          class="call-status-menu">
  <mat-list>
    <mat-selection-list [(ngModel)]="selectedStatus"
                        [multiple]="false"
                        [ngModelOptions]="{standalone: true}"
                        (click)="$event.stopPropagation()">
      <mat-list-option *ngFor="let status of callStatusOptions"
                       (click)="$event.stopPropagation()"
                       [value]="status">
        {{ ('communication.fullCallStatuses.' + status) | translate }}
      </mat-list-option>
    </mat-selection-list>
  </mat-list>
  <div layout="row"
       layout-align="end end"
       class="padding-8px">
    <button mat-raised-button
            (click)="onSaveCallStatus()"
            color="primary"
            [disabled]="!selectedStatus">
      {{ 'global.save' | translate }}
    </button>
  </div>
</mat-menu>