import { Pipe, PipeTransform } from '@angular/core';
import { IbanUtilService } from '../../../core/services/iban-util.service';

@Pipe({
  name: 'documentExtensionIcon'
})
export class UserDocumentIconPipe implements PipeTransform {

  constructor(
  ){}

  transform(name: string): string {
    const imgFormats = ['.jpg', '.jpeg', '.png', 'gif', ];
    return imgFormats.some(f => name.endsWith(f)) ? 'image' : 'description';
  }
}
