import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable, Subject } from 'rxjs';
import { Email, DeserializedEmail, SendEmailDTO, EmailContent } from '../types/email';
import { Page } from '../types/page';
import { SearchOptions } from '../types/search-options';
import { SearchOperations } from '../types/search-criterium';
import { ClientCommunicationRequestService } from './client-communication-request.service';
import * as moment from 'moment';
import { EmailTemplateCode } from '../types/email-template-code';
import { SuppressionDestination } from '../types/suppression-destination';

@Injectable()
export class EmailService {
  constructor(private request: ClientCommunicationRequestService) { }

  onEmailSend = new Subject<Email>();

  // deserializeEmail(emailToDeserialize: Email): DeserializedEmail {
  //   return {
  //     ...emailToDeserialize,
  //     sent_at:
  //       emailToDeserialize.sent_at && moment(new Date(emailToDeserialize.sent_at)).format('DD.MM.YYYY HH:mm') ,
  //   };
  // }

  serializeEmail(emailToSerialize: DeserializedEmail): Email {
    return {
      ...emailToSerialize,
      sent_at: new Date(emailToSerialize.sent_at).toISOString(),
    };
  }

  getEmailList(options?: any): Observable<Page<DeserializedEmail>> {
    return this.request.get(['email'], options)
  }


  getEmailContentById(emailId: number): Observable<EmailContent> {
    return this.request.get(['email', emailId, 'content']);
  }

  sendEmail(dto: { clientId: number, emailTemplateCode: EmailTemplateCode }) {
    console.log(dto);
    return this.request.post(['email', 'send'], {
      body: { client_id: dto.clientId },
      params: { code: dto.emailTemplateCode }
    });
  }

  sendEmailConfirmation(clientId) { //: Observable<any> 
    return this.request.post(['email', 'send', 'email-confirmation'], {
      body: { clientId },
    });
  }

  sendTemporaryPassword(clientId) { //: Observable<any> 
    return this.request.post(['email', 'send', 'temporary-password'], {
      body: { clientId },
    });
  }

  resendEmail(emailId: number, options?): Observable<Email> {
    return this.request.post(['email', emailId, 'resend']);
  }

  getSuppressionDestination(value: string): Observable<SuppressionDestination> {
    return this.request.get(['email', 'suppression-list', value]);
  }

  deleteSuppressionDestination(value: string): Observable<void> {
    return this.request.delete(['email', 'suppression-list', value]);
  }

  getEmailPlaceholderList(emailTemplateBody: string): string[] {
    const charFound = false;
    const openTag = '{{';
    const closeTag = '}}';
    const placeholderList: string[] = [];

    for (let i = 0; i < emailTemplateBody.length; i++) {
      const firstOpenTag = emailTemplateBody.indexOf(openTag, i);
      if (firstOpenTag !== -1) {
        const nextClosingTag = emailTemplateBody.indexOf(
          closeTag,
          firstOpenTag
        );
        if (nextClosingTag !== -1) {
          const currentPlaceHolder = emailTemplateBody.substring(
            firstOpenTag + openTag.length,
            nextClosingTag
          );
          if (placeholderList.indexOf(currentPlaceHolder) === -1) {
            placeholderList.push(currentPlaceHolder);
          }
          i = nextClosingTag + closeTag.length - 1;
        }
      }
    }

    return placeholderList;
  }
}
