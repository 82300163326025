<div class="main-box">
  <!-- Click2Call -->
  <mat-card appearance="outlined"
            class="c2c-card">
    <div class="c2c-box">
      <form [formGroup]="click2CallForm"
            [itfgPermissions]="['CALLS_ADD']"
            (ngSubmit)="onClick2Call()"
            class="c2c-form">
        <mat-form-field class="c2c-form-field"
                        subscriptSizing="dynamic">
          <mat-label>{{ 'global.phoneNumber' | translate }}</mat-label>

          <div class="c2c-input">
            <span class="category"
                  *ngIf="click2CallForm.get('country').value"
                  class="fi fi-{{
                click2CallForm.get('country').value?.code.toLowerCase()
              }}"
                  (click)="countrySelect.open()"></span>

            <input matInput
                   #inputClick2Call
                   formControlName="phoneNumber"
                   (keydown.enter)="$event.preventDefault(); onEnterPress();"
                   [matAutocomplete]="auto" />

            <mat-select #countrySelect
                        formControlName="country"
                        (selectionChange)="onCountryChange($event.value)"
                        class="hidden-select">
              <mat-option *ngFor="let country of countries"
                          [value]="country">
                <span class="fi fi-{{ country.code.toLowerCase() }}"></span>

                <span class="country-name">{{ country.name }}</span>

                <span class="country-dial-code">{{ country.dialCode }}</span>
              </mat-option>
            </mat-select>

            <mat-autocomplete autoActiveFirstOption
                              #auto="matAutocomplete"
                              (optionSelected)="selectCountry($event.option.value)">
              <mat-option *ngFor="let country of matchingCountries"
                          [value]="country">
                <span class="fi fi-{{ country.code.toLowerCase() }}"></span>

                <span class="country-name">{{ country.name }}</span>

                <span class="country-dial-code">{{ country.dialCode }}</span>
              </mat-option>
            </mat-autocomplete>
          </div>

          <button matSuffix
                  [disabled]="!click2CallForm.get('phoneNumber').value"
                  mat-icon-button
                  (click)="resetPhoneNumber()"
                  class="dimgrey">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <button *ngIf="!(connectingToPbx$ | async)"
                type="submit"
                mat-icon-button
                [disabled]="(connectingToPbx$ | async) || !currentlyLoggedInOperator?.phoneExtension || (['CALLS_ADD'] | isUnauthorized | async)"
                [matTooltip]="'pbx.actions.call' | translate"
                [ngClass]="{ 'enter-pressed': isEnterPressed }">
          <mat-icon>call</mat-icon>
        </button>

        <button *ngIf="(connectingToPbx$ | async)"
                type="static"
                mat-icon-button
                matTooltip="{{ 'pbx.callStages.CONNECTING' | translate }}">
          <mat-icon fontSet="material-symbols-outlined"
                    class="fill">settings_phone</mat-icon>
        </button>
      </form>
    </div>
  </mat-card>

  <!-- Calls (& Categories) -->
  <mat-card appearance="outlined"
            class="calls-card">
    <div class="categories-box">
      <mat-button-toggle-group #categoriesToggleGroup="matButtonToggleGroup"
                               hideSingleSelectionIndicator
                               [(ngModel)]="selectedCategory">

        <mat-button-toggle *ngFor="let category of categories.slice(0, 2)"
                           [value]="category"
                           (click)="getCalls(category.id)"
                           [matTooltip]="category.label"
                           matTooltipPosition="above">
          <div class="svg-icon"
               [innerHTML]="category.icon | itfgSanitizeHtml"></div>
        </mat-button-toggle>

        <mat-button-toggle *ngxPermissionsOnly="['CALLS_CALLBACK_READ']"
                           [value]="callsNoAgentCategory"
                           (click)="getCalls(callsNoAgentCategory.id || callsNoAgentCategory.NO_AGENT)"
                           matTooltip="{{ 'communication.callCategoryTypes.no_agent' | translate }}"
                           matTooltipPosition="above">
          <div class="svg-icon"
               [innerHTML]="callsNoAgentCategory.icon | itfgSanitizeHtml">
          </div>
        </mat-button-toggle>

        <mat-button-toggle *ngFor="let category of categories.slice(2)"
                           [value]="category"
                           (click)="getCalls(category.id)"
                           [matTooltip]="category.label"
                           matTooltipPosition="above">
          <div class="svg-icon"
               [innerHTML]="category.icon | itfgSanitizeHtml"></div>
        </mat-button-toggle>

      </mat-button-toggle-group>
    </div>

    <div class="call-list-box">
      <!-- Spinner whenever we are fetching the event log -->
      <div *ngIf="isFetchingCalls"
           class="progress-spinner-container">
        <mat-spinner class="progress-spinner"
                     strokeWidth="10"
                     [diameter]="150"></mat-spinner>
      </div>

      <mat-selection-list *ngIf="!isFetchingCalls"
                          role="list">
        <mat-list-item *ngFor="let call of calls"
                       itfgDetectMouseClick
                       (singleClick)="openRightDrawer(call)"
                       (doubleClick)="navigateCallToNewTab(call)"
                       (ctrlClick)="navigateCallToNewTab(call)"
                       [ngClass]="{ selected: call?.id === selectedCall?.id }">
          <div class="tile-box">
            <span class="tile-icon-box">
              <itfg-call-direction-and-status-icon [call]="call"></itfg-call-direction-and-status-icon>
            </span>

            <span class="tile-content-box">
              <div flex
                   layout="row"
                   layout-align="end space-between"
                   class="tile-content-title">
                <span>{{
                  call.phoneNumber === hiddenNumber
                  ? ('communication.hiddenNumber' | translate)
                  : call.phoneNumber
                  }}
                </span>
              </div>

              <div class="tile-content-line">
                {{ call.createdAt | itfgDateFormat }}
              </div>
            </span>
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="calls && calls.length === 0"
                       disabled>
          <div class="empty-tile">{{ 'pbx.missingCalls' | translate }}</div>
        </mat-list-item>
      </mat-selection-list>
    </div>

    <div class="controls-box">
      <button mat-button
              class="view-more dimgrey"
              (click)="navigateToCallsHistory()">
        Виж повече
      </button>
    </div>
  </mat-card>
</div>

<mat-menu #additionalControls="matMenu">
  <a *ngxPermissionsOnly="['CALLS_READ']"
     mat-menu-item
     target="_blank"
     routerLink="/communication/calls">
    <mat-icon fontSet="material-symbols-outlined"
              class="fill">
      call_log
    </mat-icon>
    <span [translate]="'communication.callsReport'">Calls report</span>
  </a>
</mat-menu>
